/* eslint-disable react/no-did-mount-set-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Bowser from 'bowser';
import queryString from 'query-string';

import inkblotLogoLight from '../../images/_inkblot-logo-light.png';
import inkblotLogoDark from '../../images/nav/inkblot-logo.svg';
import inkblotLogoMobile from '../../images/nav/inkblot-logo-mobile.svg';
import SignUp from '../../components/meditation/SignUp';
import HamburgerBlack from '../../images/_hamburger.svg';
import HamburgerWhite from '../../images/_hamburger-white.svg';
import close from '../../images/x.svg';

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigationShow: false,
      signUpOpen: false,
      isScrollDown: this.getScrollTop() > 60,
    };
  }

  componentWillMount() {
    this.closeAuths();
  }

  componentDidMount() {
    const { location } = this.props;

    window.addEventListener('scroll', () => {
      this.setState({ isScrollDown: this.getScrollTop() > 60 });
    });

    const params = queryString.parse(location.search);
    const signUp = params.signup === 'true';

    if (signUp) {
      localStorage.setItem('signUp', 'open');
      this.setState({ signUpOpen: true });
    }

    localStorage.getItem('signUp');
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.getScrollTop, false);
  }

  setLanguage = lang => {
    localStorage.setItem('selectedLanguage', lang);
    window.location.reload();
  };

  getScrollTop = () =>
    (window.pageYOffset !== undefined
      ? window.pageYOffset
      : (document.documentElement || document.body.parentNode || document.body)
        .scrollTop);

  setTempBannerHeight = height => this.setState({ tempBannerHeight: height });

  pushOver = element => {
    const browser = Bowser.getParser(window.navigator.userAgent);

    if (browser.getBrowser().name !== 'Safari') {
      if (element === 'signUp') {
        const amount = window.innerWidth < 600 ? window.innerWidth : 600;
        document.getElementById('___gatsby').style.right = `${amount}px`;
      }
    }
  };

  toggleElements = element => {
    this.setState(prevState => ({ [element]: !prevState[element] }));
    this.pushOver(element);
  };

  toggleSignUpDrawer = () => {
    this.setState({
      navigationShow: false,
      signUpOpen: true,
    });
    localStorage.setItem('signUp', 'open');
    this.pushOver('signUp');
    this.forceUpdate();
  };

  closeAuths = () => {
    this.setState({
      signInOpen: false,
      signUpOpen: false,
    });
    localStorage.setItem('signUp', 'close');
    document.getElementById('___gatsby').style.right = '0px';
    this.forceUpdate();
  };

  renderLinks = () => (
    <div className="nav-item-container" style={{ justifyContent: 'flex-end' }}>
      <div className="auth-buttons" style={{ justifyContent: 'flex-end' }}>
        <button
          className="medium-blue-button"
          onClick={() => {
            this.setState({ navigationShow: false, signInOpen: false });
            localStorage.setItem('signUp', 'open');
          }}
        >
          <FormattedMessage id="navigation.createMyAccount" defaultMessage="Get Started" />
        </button>
      </div>
    </div>
  );

  renderTopBar = () => (
    <div className="top-navigation-bar">
      <div className="email-support">
        <FormattedHTMLMessage
          tagName="span"
          id="meditation.emailSupport"
          defaultMessage="Have a question? Send us an email to <a href='mailto:meditation@inkblot.care'>meditation@inkblot.care</a>"
        />
      </div>
      <div>
        <Link to="/">
          <FormattedHTMLMessage
            tagName="span"
            id="meditation.goToInkblot"
            defaultMessage="Go to Inkblot"
          />
        </Link>
      </div>
    </div>
  )

  renderNavBar = () => {
    const {
      navigationShow, signUpOpen,
    } = this.state;

    const hamburger = HamburgerBlack;
    const inkblotLogo = inkblotLogoDark;

    const signUp = localStorage.getItem('signUp') === 'open' || signUpOpen;

    return (
      <div className="navigation-bar-container">
        <Link to="/">
          <img className="ink-logo" title="Inkblot" src={inkblotLogo} alt="Logo" />
        </Link>
        <div className="navigation-bar-right">{this.renderLinks()}</div>
        <div className="navigation-bar-right-mobile">
          <div role="presentation" onClick={() => this.toggleElements('navigationShow')}>
            <img style={{ marginTop: '5px', cursor: 'pointer' }} src={hamburger} alt="hamburger" />
          </div>
        </div>
        <div
          style={navigationShow ? { height: '100%' } : { height: '0%' }}
          className="navigation-overlay-mobile"
        >
          <div role="presentation" onClick={() => this.toggleElements('navigationShow')}>
            <img className="navigation-mobile-close" src={close} alt="" />
          </div>
          <div className="navigation-overlay-mobile-content">
            <Link to="/">
              <img className="ink-logo" title="Inkblot" src={inkblotLogoMobile} alt="Logo" />
            </Link>
            {this.renderLinks()}
            <Link
              to="/"
              onClick={() => this.setState({ navigationShow: false })}
              className="for-practitioners"
            >
              <div style={{ fontSize: '19px' }}>
                <FormattedMessage id="meditation.goToInkblot" defaultMessage="Go to Inkblot" />
              </div>
            </Link>
          </div>
        </div>
        <SignUp
          signUpOpen={signUp === true}
          toggleSignUpDrawer={() => this.closeAuths()}
        />
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderTopBar()}
        {this.renderNavBar()}
      </div>
    );
  }
}

Navigation.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Navigation;
