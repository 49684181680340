/* eslint-disable prefer-rest-params */
/* eslint-disable func-names */
const INKBLOT_APP_ID = 'a9ypvixw';

function isEnabled() {
  return window && typeof window.Intercom === 'function';
}

export function injectIntercom(locale = 'en') {
  (function () {
    const w = window;
    const ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      const d = document;
      const i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      const l = function () {
        const s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = `https://widget.intercom.io/widget/${INKBLOT_APP_ID}`;
        const x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === 'complete') {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  }());

  if (isEnabled()) {
    window.Intercom('boot', {
      app_id: INKBLOT_APP_ID,
      language_override: locale.substr(0, 2),
    });
  }
}

export function setIntercomUser(userId, email, name, locale = 'en') {
  if (isEnabled()) {
    window.Intercom('shutdown');
    window.Intercom('boot', {
      app_id: INKBLOT_APP_ID,
      user_id: userId,
      email,
      name,
      language_override: locale.substr(0, 2),
    });
  }
}

export function setIntercomVisitorLocale(locale = 'en') {
  if (isEnabled()) {
    window.Intercom('shutdown');
    window.Intercom('boot', {
      app_id: INKBLOT_APP_ID,
      language_override: locale.substr(0, 2),
    });
  }
}
