import React, { Component } from 'react';
import { Link } from 'gatsby';
import inkblotLogo from '../../images/_ink-logo.svg';
import hamburger from '../../images/_hamburger.svg';

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigationShow: false,
    };
  }

  toggleElements = element => {
    this.setState(prevState => ({ [element]: !prevState[element] }));
  };

  renderLinks = () => (
    <div>
      <li>
        <Link title="About Us" onClick={() => this.setState({ navigationShow: false })} to="/about">
          About Us
        </Link>
      </li>

      <li>
        <a title="Sign In" href="https://app.inkblottherapy.com/signin">
          Sign in
        </a>
      </li>
      <li>
        <a title="Get Started" href="https://app.inkblottherapy.com/signup?affiliation=sunlife">
          Get Started
        </a>
      </li>
    </div>
  );

  render() {
    const { navigationShow } = this.state;
    return (
      <div className="navigation-bar-container">
        <Link to="/">
          <img title="Inkblot" src={inkblotLogo} alt="Logo" />
        </Link>
        <ul className="navigation-bar-right">{this.renderLinks()}</ul>
        <div className="navigation-bar-right-mobile">
          <div role="presentation" onClick={() => this.toggleElements('navigationShow')}>
            <img style={{ marginTop: '5px' }} src={hamburger} alt="hamburger" />
          </div>
        </div>
        <div
          style={navigationShow ? { height: '100%' } : { height: '0%' }}
          className="navigation-overlay-mobile"
        >
          <div role="presentation" onClick={() => this.toggleElements('navigationShow')}>
            <span className="navigation-mobile-close">X</span>
          </div>
          <ul className="navigation-overlay-mobile-content">{this.renderLinks()}</ul>
        </div>
      </div>
    );
  }
}

export default Navigation;
