import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { IntlProvider, addLocaleData } from 'react-intl';
import { HelmetProvider } from 'react-helmet-async';
import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';
import enCa from '../translations/en-CA.json';
import frCa from '../translations/fr-CA.json';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import { injectIntercom } from '../utils/intercomHelpers';
import CustomNavigation from '../components/custom/CustomNavigation';
import GallivanNavigation from '../components/gallivan/Navigation';
import GallivanFooter from '../components/gallivan/Footer';
import SunLifeNavigation from '../components/sunlife/Navigation';
import MeditationNavigation from '../components/meditation/Navigation';
import BusinessNavigation from '../pagePartials/business/BusinessNavigation';
import EventsNavigation from '../pagePartials/business/EventsNavigation';
import LeagueNavigation from '../components/League/Navigation';
import LeslieNavigation from '../components/Leslie/Navigation';
import OmersNavigation from '../components/Omers/Navigation';
import {
  isGallivanPage,
  isSunLife,
  whichCompany,
  isLeagueX,
  isMLF,
  isLeslie,
  isOmers,
  isPPCHealthAkira,
  isPPCHealthMaple,
  isCSA,
  isPPCHealthLifeInc,
  isPPCHealthBCI,
  isATB,
  isPPCHealthBimbo,
  noIntercom,
  isCorporateNav,
} from '../utils/pageUtils';
import { isBusiness, isEvents } from '../pagePartials/business';
import '../styles/index.scss';
import SEO from '../utils/SEO';
import { initAmplitude } from '../utils/amplitude';

const renderLayouts = (children, location) => {
  if (
    [
      'forgot_password',
      'reset_password',
      'onboarding',
      'sign_in',
      'sign_up',
      'dependants',
      'two_factor',
    ].includes(location.pathname.split('/')[1])
  ) {
    return <div>{children}</div>;
  }

  if (whichCompany().path !== '') {
    return (
      <div>
        <CustomNavigation location={location} />
        {children}
        <Footer />
      </div>
    );
  }

  if (isGallivanPage(location.pathname)) {
    return (
      <div>
        <GallivanNavigation />
        {children}
        <GallivanFooter />
      </div>
    );
  }

  if (isBusiness(location.pathname)) {
    return (
      <div>
        <BusinessNavigation location={location} />
        {children}
        <Footer />
      </div>
    );
  }

  if (isEvents(location.pathname)) {
    return (
      <div>
        <EventsNavigation />
        {children}
        <Footer />
      </div>
    );
  }

  if (location.pathname.split('/')[1] === 'invest') {
    return <div>{children}</div>;
  }

  if (location.pathname.split('/')[1] === 'meditation') {
    return (
      <div>
        <MeditationNavigation location={location} />
        {children}
        <Footer />
      </div>
    );
  }

  if (location.pathname.split('/')[1] === 'avail') {
    return (
      <div>
        <Navigation location={location} />
        {children}
        <Footer />
      </div>
    );
  }

  if (isSunLife(location.pathname)) {
    return (
      <div>
        <SunLifeNavigation />
        {children}
        <Footer />
      </div>
    );
  }

  if (isLeagueX(location.pathname)) {
    return (
      <div>
        <LeagueNavigation location={location} />
        {children}
        <Footer />
      </div>
    );
  }
  if (isLeslie(location.pathname)) {
    return (
      <div>
        <LeslieNavigation location={location} />
        {children}
        <Footer />
      </div>
    );
  }
  if (isOmers(location.pathname)) {
    return (
      <div>
        <OmersNavigation location={location} />
        {children}
        <Footer />
      </div>
    );
  }
  if (isMLF(location.pathname)) {
    return <div>{children}</div>;
  }
  if (
    isPPCHealthAkira(location.pathname) ||
    isPPCHealthMaple(location.pathname) ||
    isCSA(location.pathname) ||
    isPPCHealthLifeInc(location.pathname) ||
    isPPCHealthBCI(location.pathname) ||
    isATB(location.pathname) ||
    isPPCHealthBimbo(location.pathname) ||
    isCorporateNav(location.pathname)
  ) {
    return <div>{children}</div>;
  }

  // default
  return (
    <div>
      <Navigation location={location} />
      {children}
      <Footer />
    </div>
  );
};

class Layout extends React.Component {
  constructor() {
    super();

    if (typeof window !== 'undefined') {
      if (localStorage.getItem('selectedLanguage') === null) {
        localStorage.setItem('selectedLanguage', 'en');
      }
      if (noIntercom(window.location.pathname)) {
        injectIntercom(localStorage.getItem('selectedLanguage') === 'fr' ? 'fr' : 'en');
      }
    }
  }

  componentDidMount() {
    initAmplitude();
  }

  render() {
    const { children, location } = this.props;

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={() => {
          if (typeof window !== 'undefined') {
            addLocaleData([...en, ...fr]);

            const languagePreference = localStorage.getItem('selectedLanguage');
            let locale = 'en';
            let messages = enCa;

            if (languagePreference === 'en' || null) {
              locale = 'en';
              messages = enCa;
            }

            if (languagePreference === 'fr') {
              locale = 'fr';
              messages = frCa;
            }

            return (
              <div>
                {typeof window !== 'undefined' && (
                  <HelmetProvider>
                    <div className="layout-container">
                      <SEO
                        pathname={location.pathname.split('/')[1]}
                        lang={locale}
                      />
                      <IntlProvider locale={locale} messages={messages}>
                        {renderLayouts(children, location)}
                      </IntlProvider>
                    </div>
                  </HelmetProvider>
                )}
              </div>
            );
          }
          return <div />;
        }}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  location: PropTypes.object.isRequired,
};

export default Layout;
