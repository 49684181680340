/* eslint-disable max-len */
// Logos
import { COUNTRY } from '../../utils/environment';
import mapleleaffoodsLogo from '../../images/custom/logos/mapleleaffoods-logo.png';
import atbLogo from '../../images/custom/logos/atb-colour-logo.jpg';
import dmzLogo from '../../images/custom/logos/dmz-logo.png';
import velocityLogo from '../../images/custom/logos/velocity-logo.png';
import pelmorexLogo from '../../images/custom/logos/pelmorex-logo.png';
import prosperLogo from '../../images/custom/logos/prosper-logo.png';
import uberLogo from '../../images/custom/logos/uber-logo.png';
import wellLogo from '../../images/custom/logos/well-logo.png';
import esgLogo from '../../images/custom/logos/esg-logo.png';
import partnerLogo from '../../images/custom/logos/partner-logo.png';
import actoLogo from '../../images/custom/logos/acto-logo.png';
import streetContxtLogo from '../../images/custom/logos/street-contxt-logo.png';
import loopioLogo from '../../images/custom/logos/loopio-logo.png';
import partnerStackLogo from '../../images/custom/logos/partnerstack-logo.svg';
import leagueLogo from '../../images/custom/logos/league-logo.svg';
import indigoLogo from '../../images/custom/logos/indigo-logo.png';
import tucowsLogo from '../../images/custom/logos/tucows-logo.png';
import diamondLogo from '../../images/custom/logos/diamond-logo.png';
import techstarsLogo from '../../images/custom/logos/techstars-logo.png';
import genuineHealthLogo from '../../images/custom/logos/genuine-health-logo.jpeg';
import tribalscaleLogo from '../../images/custom/logos/tribalscale-logo.png';
import redoxLogo from '../../images/custom/logos/redox-logo.png';
import sleepCountryLogo from '../../images/custom/logos/sleep-country-logo.png';
import tspLogo from '../../images/custom/logos/TSP_Logo.jpg';
import ratioLogo from '../../images/custom/logos/ratio-city-logo.png';
import afiliasLogo from '../../images/custom/logos/afilias-logo.png';
import gnwLogo from '../../images/custom/logos/goodnwell_logo.png';
import reunionLogo from '../../images/custom/logos/reunion-logo.png';
import tebcoLogo from '../../images/custom/logos/tebco-logo.jpg';
import flipgiveLogo from '../../images/custom/logos/flipgive-logo.jpg';
import lyricfindLogo from '../../images/custom/logos/lyricfind-logo.png';
import uhfLogo from '../../images/custom/logos/uhf-logo.jpg';
import cbnLogo from '../../images/custom/logos/cbn-logo.jpg';
import cbnLogo2 from '../../images/custom/logos/cbn-logo-2.jpg';
import seamlessLogo from '../../images/custom/logos/seamless-logo.jpg';
import iqOfficesLogo from '../../images/custom/logos/iq-offices-logo.png';
import maxPeopleLogo from '../../images/custom/logos/max-people-logo.jpg';
import duttonBrockLogo from '../../images/custom/logos/dutton-brock-logo.jpg';
import minesenseLogo from '../../images/custom/logos/minesense-logo.png';
import vistarLogo from '../../images/custom/logos/vistarmedia-logo.png';
import tpsLogo from '../../images/custom/logos/tps-logo.png';
import questicaLogo from '../../images/custom/logos/questica-logo.png';
import morguardLogo from '../../images/custom/logos/morguard-logo.jpg';
import thinkingApeLogo from '../../images/custom/logos/thinking-ape-logo.png';
import fortressLogo from '../../images/custom/logos/fortress-logo.png';
import discoveryDentalLogo from '../../images/custom/logos/discover-dental-logo.png';
import blanclabsLogo from '../../images/custom/logos/blanclabs-logo.png';
import hoemLogo from '../../images/custom/logos/hoem-logo.png';
import mywellnessLogo from '../../images/gallivan/_myWellnessLogo.png';
import keyLivingLogo from '../../images/custom/logos/key-living-logo.png';
import dreamLogo from '../../images/custom/logos/dream-logo.png';
import hearthstoneLogo from '../../images/custom/logos/hearthstone-logo.png';
import citronLogo from '../../images/custom/logos/citron-logo.png';
import valleyviewLogo from '../../images/custom/logos/valleyview-logo.jpg';
import forestviewLogo from '../../images/custom/logos/forestview-logo.jpg';
import sciLtdLogo from '../../images/custom/logos/sci-ltd-logo.jpg';
import sciLtdLogo2 from '../../images/custom/logos/sci-ltd-logo2.jpg';
import guaranteedLogo from '../../images/custom/logos/guaranteed-removals-logo.png';
import carbon60Logo from '../../images/custom/logos/carbon60-logo.png';
import trilliumLogo from '../../images/custom/logos/trillium-logo.png';
import newtopiaLogo from '../../images/custom/logos/newtopia-logo.png';
import csaLogo from '../../images/custom/logos/csa-logo-en.jpg';
import csaLogoFr from '../../images/custom/logos/csa-logo-fr.jpg';
import sinkingShipLogo from '../../images/custom/logos/sinking-ship-logo.png';
import motorefiLogo from '../../images/custom/logos/motorefi-logo.png';
import ioofLogo from '../../images/custom/logos/ioof-logo.png';
import csLogo from '../../images/custom/logos/cs-logo.jpg';
import kingcoleducksLogo from '../../images/custom/logos/king-cole-ducks-logo.jpg';
import ochLogo from '../../images/custom/logos/och-logo.png';
import brightandearlyLogo from '../../images/custom/logos/brightandearly-logo.png';
import thinkonLogo from '../../images/custom/logos/thinkon-logo.png';
import flybitsLogo from '../../images/custom/logos/flybits-logo.png';
import weLogo from '../../images/custom/logos/we-logo.png';
import accmLogo from '../../images/custom/logos/accm-logo.png';
import bonfireLogo from '../../images/custom/logos/bonfire-logo.png';
import sensibillLogo from '../../images/custom/logos/sensibill-logo.jpg';
import varicentLogo from '../../images/custom/logos/varicent-logo.png';
import caesarsLogo from '../../images/custom/logos/caesars-logo.png';
import intellijointLogo from '../../images/custom/logos/intellijoint-logo.png';
import ncaLogo from '../../images/custom/logos/nca-logo.png';
import rbLogo from '../../images/custom/logos/rb-logo.png';
import sunwingLogo from '../../images/custom/logos/sunwing-logo.png';
import edealerLogo from '../../images/custom/logos/edealer-logo.png';
import cosmoLogo from '../../images/custom/logos/cosmo-logo.jpg';
import grewLogo from '../../images/custom/logos/gcrew-logo.png';
import alliancefranchiseLogo from '../../images/custom/logos/alliance-franchise-logo.png';
import canadiantireLogo from '../../images/custom/logos/canadian-tire-logo.png';
import ibigroupLogo from '../../images/custom/logos/ibi-group-logo.jpg';
import conveneLogo from '../../images/custom/logos/convene-logo.png';
import intelexLogo from '../../images/custom/logos/intelex-logo.png';
import bciLogo from '../../images/custom/logos/bci-logo.png';
import cdlsiLogo from '../../images/custom/logos/cdlsi-logo.png';
import appcentricaLogo from '../../images/custom/logos/appcentrica-logo.png';
import campbrainLogo from '../../images/custom/logos/campbrain-logo.jpg';
import kiraSystemsLogo from '../../images/custom/logos/kira-systems-logo.png';
import baycrestLogo from '../../images/custom/logos/baycrest-logo.png';
import aeconLogo from '../../images/custom/logos/aecon-logo.png';
import willfulLogo from '../../images/custom/logos/willful-logo.png';
import mejuriLogo from '../../images/custom/logos/mejuri-logo.png';
import forthspaceLogo from '../../images/custom/logos/forthspace-logo.png';
import canadalearningcodeLogo from '../../images/custom/logos/canadalearningcode-logo.png';
import endyLogo from '../../images/custom/logos/endy-logo.jpg';
import securitycompassLogo from '../../images/custom/logos/securitycompass-logo.png';
import hvInvestmentsLogo from '../../images/custom/logos/hv-investments-logo.jpg';
import hvPropertiesLogo from '../../images/custom/logos/hv-properties-logo.jpg';
import insightLogo from '../../images/custom/logos/insight-logo.png';
import dsb1Logo from '../../images/custom/logos/dsb1-logo.png';
import ocpLogo from '../../images/custom/logos/ocp-logo.png';
import daviesLogo from '../../images/custom/logos/davies-logo.jpg';
import bbfLogo from '../../images/custom/logos/bbf-logo.png';
import betworksLogo from '../../images/custom/logos/betworks-logo.png';
import fireflyLogo from '../../images/custom/logos/firefly-logo.jpeg';
import unifiedAlloysLogo from '../../images/custom/logos/unified-alloys-logo.png';
import altusLogo from '../../images/custom/logos/altus-logo.png';
import cglLogo from '../../images/custom/logos/cgl-logo.png';
import moseyLogo from '../../images/custom/logos/mosey-logo.jpg';
import yorkLogo from '../../images/custom/logos/york-logo.jpg';
import kearneyLogo from '../../images/custom/logos/kearney-logo.png';
import thgLogo from '../../images/custom/logos/thg-logo.png';
import niLogo from '../../images/custom/logos/ni-logo.jpg';
import benchLogo from '../../images/custom/logos/bench-logo.png';
import fullyManagedLogo from '../../images/custom/logos/fully-managed-logo.png';
import benevityLogo from '../../images/custom/logos/benevity-logo.png';
import humiLogo from '../../images/custom/logos/humi-logo.png';
import kohoLogo from '../../images/custom/logos/koho-logo.png';
import fugroLogo from '../../images/custom/logos/fugro-logo.jpg';
import brandHeroesLogo from '../../images/custom/logos/brand-heroes-logo.png';
import flippLogo from '../../images/custom/logos/flipp-logo.png';
import cnbLogo from '../../images/custom/logos/cnb-logo.jpg';
import kasianLogo from '../../images/custom/logos/kasian-logo.png';
import macgregorsLogo from '../../images/custom/logos/macgregors-logo.jpg';
import nbplLogo from '../../images/custom/logos/nbpl-logo.jpg';
import colliganLogo from '../../images/custom/logos/colligan-logo.jpg';
import mccoLogo from '../../images/custom/logos/mcco-logo.png';
import velaLogo from '../../images/custom/logos/vela-logo.png';
import omvicLogo from '../../images/custom/logos/omvic-logo.jpg';
import wirthLogo from '../../images/custom/logos/wirth-logo.png';
import atpLogo from '../../images/custom/logos/atp-logo.png';
import clearbancLogo from '../../images/custom/logos/clearbanc-logo.png';
import hooppLogo from '../../images/custom/logos/hoopp-logo.png';
import nanoleafLogo from '../../images/custom/logos/nanoleaf-logo.png';
import viafouraLogo from '../../images/custom/logos/viafoura-logo.png';
import intervalLogo from '../../images/custom/logos/interval-logo.jpg';
import browzeLogo from '../../images/custom/logos/browze-logo.jpeg';
import neoLogo from '../../images/custom/logos/neo-logo.jpg';
import astraLogo from '../../images/custom/logos/astra-logo.png';

const companies = [
  // Company with Employee ID template
  {
    marketingURL: 'wcl',
    path: 'wcl',
    title: 'Caesars Windsor',
    logo: caesarsLogo,
    logoClass: 'key-living-logo',
    dependants: true,
    minutes: '5 hours',
    partial: true,
    EFAP: true,
    EAP: true,
    employeeIdSignup: true,
  },
  {
    marketingURL: 'canadiantire',
    path: 'canadiantire',
    title: 'Canadian Tire',
    logo: canadiantireLogo,
    logoClass: 'key-living-logo',
    dependants: true,
    employeeIdSignup: true,
    frenchPage: true,
    subsequentSessions: 'Your initial 30 minute individual session is complimentary.',
    subsequentSessionsFr: 'Votre première séance individuelle de 30 minutes est gratuite.',
    removeSecondParagraph: true,
    finalParagraph: 'Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be covered by your private health insurance, your CTC company benefit plan or paid privately.',
    finalParagraphFr: "Les séances individuelles subséquentes ne coûtent que 75 $ / heure (120 $ / heure pour les conseils aux couples) qui peuvent être remboursées par le biais de votre assurance maladie privée, votre régime d'avantages sociaux CTC ou payées en privé.",
  },
  {
    marketingURL: 'cnb',
    path: 'cnb',
    title: 'City of North Bay',
    logo: cnbLogo,
    logoClass: 'key-living-logo',
    dependants: true,
    employeeIdSignup: true,
    EAP: true,
    EFAP: true,
    crisisText: true,
  },
  {
    marketingURL: 'yorkregion',
    path: 'yorkregion',
    title: 'York Region',
    logo: yorkLogo,
    logoClass: 'corporate-nav-logo',
    dependants: true,
    employeeIdSignup: true,
  },
  {
    marketingURL: 'benevity',
    path: 'benevity',
    title: 'Benevity',
    logo: benevityLogo,
    logoClass: 'key-living-logo',
    employeeIdSignup: true,
    showFlags: true,
    EFAP: true,
    crisisText: true,
    dependants: true,
    usNotFree: true,
    efapSecond: 'Your initial individual counselling session is complimentary, and through the EFAP, your next 5 hours of counselling per year are covered. After that, you can continue counselling with sessions reimbursable through your paramedical health coverage and/or HSA. Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling).',
  },
  // Access Company template
  // {
  //   path: 'path', // This is the same as the customized_url in the database
  //   marketingURL: 'path', // Usually the same as the path but can be customized for companies with multiple portal pages
  //   title: 'Title', // Name of the company as shown on Landing page
  //   logo: companyLogo, // Logo imported from above
  //   logoClass: 'class-name', // Use 'corporate-nav-logo' for wider, rectangular logos and 'key-living-logo' for smaller, square or circular logos
  //   dependants: true, // True if company includes dependants
  //   subsequentSessions: 'Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be covered by your private health insurance, benefits coverage or paid privately.', // To be customized if the last paragraph is different than the default
  //   frenchPage: true, // True if English and French
  //   subsequentSessionsFr: 'Les séances individuelles subséquentes ne coûtent que 75 $ / heure (120 $ / heure pour les conseils aux couples) qui peuvent être remboursées par le biais de votre assurance maladie paramédicale ou payées en privé.', // Needs to be customized if frenchPage: true,
  //   noneSignup: true, // True if portal gating is None
  //   partial: true, // True if portal gating is Partial
  //   full: true, // True if portal gating is Full
  //   email_domain: ['inkblottherapy.com', 'url.com'], // For Full portal gating use Inkblot url for testing and company url based on eligibility list
  // },
  // Copy the template below to start
  // {
  //   path: 'path',
  //   marketingURL: 'path',
  //   title: 'Title',
  //   logo: companyLogo,
  //   logoClass: 'key-living',
  //   dependants: true,
  //   subsequentSessions: 'Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be covered by your private health insurance, benefits coverage or paid privately.',
  //   full: true,
  //   email_domain: ['inkblottherapy.com', 'url.com'],
  // },
  {
    path: 'neo',
    marketingURL: 'neo',
    title: 'NEO Exchange',
    logo: neoLogo,
    logoClass: 'corporate-nav-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'viafoura',
    marketingURL: 'viafoura',
    title: 'Viafoura',
    logo: viafouraLogo,
    logoClass: 'corporate-nav-logo',
    fullyGated: true,
    dependants: true,
  },
  {
    path: 'interval',
    marketingURL: 'interval',
    title: 'interVal',
    logo: intervalLogo,
    logoClass: 'corporate-nav-logo',
    dependants: true,
    fullyGated: true,
  },
  {
    path: 'hoopp',
    marketingURL: 'hoopp',
    title: 'HOOPP',
    logo: hooppLogo,
    logoClass: 'key-living-logo',
    dependants: true,
    fullyGated: true,
    frenchPage: true,
    subsequentSessions: 'Subsequent sessions cost just $75/hour ($120/hour for couples counseling) and can be reimbursed through your psychological benefits coverage with Sun Life ($2500 per calendar year for you and each of your dependents).',
    subsequentSessionsFr: 'Les séances ultérieures ne coûtent que $ 75/heure ($ 120/heure pour la thérapie de couple) et peuvent être remboursées par votre couverture de prestations psychologiques avec Sun Life ($ 2500 par année civile pour vous et chacune des personnes à votre charge).',
    noEmailsSent: true,
  },
  {
    path: 'clearbanc',
    marketingURL: 'clearbanc',
    title: 'Clearbanc',
    logo: clearbancLogo,
    logoClass: 'corporate-nav-logo',
    dependants: true,
    subsequentSessions: 'For Canadian employees, individual sessions cost just $75/hour ($120/hour for couples counselling) which may be covered by your benefits coverage or paid privately. US employees can continue with sessions paid out using your health insurance. The rates will be in alignment with your benefits plan for in network mental health visits.',
    full: true,
    email_domain: ['inkblottherapy.com', 'clearbanc.com'],
    removeSecondParagraph: true,
    showFlags: true,
  },
  {
    path: 'atp',
    marketingURL: 'atp',
    title: 'ATP',
    logo: atpLogo,
    logoClass: 'key-living-logo',
    dependants: true,
    subsequentSessions: 'For Canadian employees, individual sessions cost just $75/hour ($120/hour for couples counselling) which may be covered by your private health insurance, benefits coverage or paid privately. US employees can continue with sessions paid out using your health insurance . The rates will be in alignment with your benefits plan for in network mental health visits.',
    partial: true,
    removeSecondParagraph: true,
    showFlags: true,
  },
  {
    path: 'macgregors',
    marketingURL: 'macgregors',
    title: 'Macgregors Meat & Seafood',
    logo: macgregorsLogo,
    logoClass: 'key-living-logo',
    dependants: true,
    subsequentSessions: 'Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be covered by your private health insurance, benefits coverage or paid privately.',
    noneSignup: true,
  },
  {
    marketingURL: 'fugrocanada',
    path: 'fugrocanada',
    title: 'Fugro',
    logo: fugroLogo,
    logoClass: 'key-living-logo',
    dependants: true,
    email_domain: ['inkblottherapy.com', 'fugro.com'],
    full: true,
  },
  {
    marketingURL: 'mejuri',
    path: 'mejuri',
    title: 'Mejuri',
    logo: mejuriLogo,
    logoClass: 'corporate-nav-logo',
    dependants: true,
    email_domain: ['inkblottherapy.com', 'mejuri.com'],
    full: true,
    subsequentSessions: 'Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be reimbursable through your paramedical health coverage.',
  },
  {
    marketingURL: 'acto',
    path: 'acto',
    title: 'ACTO',
    logo: actoLogo,
    logoClass: 'corporate-nav-logo',
  },
  {
    marketingURL: 'uhf',
    path: 'uhf',
    title: 'University Hospital Foundation',
    logo: uhfLogo,
    logoClass: 'key-living-logo',
  },
  {
    marketingURL: 'gcrew',
    path: 'gcrew',
    title: 'Guestlogix',
    logo: grewLogo,
    logoClass: 'key-living-logo',
    dependants: true,
    partial: true,
  },
  {
    marketingURL: '500px',
    path: '500px',
    title: '500px',
    logo: null,
    logoClass: 'key-living-logo',
    dependants: true,
  },
  {
    marketingURL: 'goodandwell',
    path: 'goodandwell',
    title: 'Good & Well',
    logo: gnwLogo,
    logoClass: 'corporate-nav-logo',
    subsequentSessions: 'Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be covered by your benefits plan or paid privately.',
  },
  {
    marketingURL: 'flybits',
    path: 'flybits',
    title: 'FlyBits',
    logo: flybitsLogo,
    logoClass: 'corporate-nav-logo',
    dependants: true,
    full: true,
    email_domain: ['inkblottherapy.com', 'flybits.com'],
  },
  {
    marketingURL: 'we',
    path: 'we',
    title: 'WE',
    logo: weLogo,
    logoClass: 'key-living-logo',
    dependants: true,
    partial: true,
    subsequentSessions: 'Your initial 30-minute individual session is complimentary and through WE, your next 60 minutes are covered. Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) and may be reimbursable under your paramedical health benefit if you have or paid privately.',
    removeSecondParagraph: true,
    finalParagraph: 'Your WE-sponsored minutes will be available through December 31, 2020.',
  },
  {
    marketingURL: 'thinkon',
    path: 'thinkon',
    title: 'ThinkOn',
    logo: thinkonLogo,
    logoClass: 'key-living-logo',
    dependants: true,
    full: true,
    email_domain: ['inkblottherapy.com', 'thinkon.com'],
  },
  {
    marketingURL: 'partnerstack',
    path: 'partnerstack',
    title: 'PartnerStack',
    logo: partnerStackLogo,
    logoClass: 'corporate-nav-logo',
  },
  {
    marketingURL: 'och',
    path: 'och',
    title: 'Ottawa Community Housing',
    logo: ochLogo,
    logoClass: 'key-living-logo',
    dependants: true,
    partial: true,

  },
  {
    marketingURL: 'fullymanaged',
    path: 'fullymanaged',
    title: 'Fully Managed',
    logo: fullyManagedLogo,
    logoClass: 'corporate-nav-logo',
    full: true,
    email_domain: ['fullymanaged.com', 'inkblottherapy.com'],
    dependants: true,
  },
  {
    marketingURL: 'ocp',
    path: 'ocp',
    title: 'Ontario College of Pharmacists',
    logo: ocpLogo,
    logoClass: 'key-living-logo',
    full: true,
    email_domain: ['ocpinfo.com', 'inkblottherapy.com'],
    dependants: true,
  },
  {
    marketingURL: 'betworks',
    path: 'betworks',
    title: 'Bet.Works',
    logo: betworksLogo,
    logoClass: 'key-living-logo',
    full: true,
    email_domain: ['bet.works', 'inkblottherapy.com'],
    dependants: true,
  },
  {
    marketingURL: 'firefly',
    path: 'firefly',
    title: 'Firefly Software',
    logo: fireflyLogo,
    logoClass: 'corporate-nav-logo',
    full: true,
    email_domain: ['fireflysoftware.ca', 'inkblottherapy.com'],
    dependants: true,
  },
  {
    marketingURL: 'altus',
    path: 'altus',
    title: 'Altus Group',
    logo: altusLogo,
    logoClass: 'corporate-nav-logo',
    full: true,
    email_domain: ['altusgroup.com', 'inkblottherapy.com'],
    frenchPage: true,
    subsequentSessions: 'Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be reimbursable through your paramedical health benefit or paid privately.',
    subsequentSessionsFr: 'Les séances individuelles subséquentes ne coûtent que 75 $ / heure (120 $ / heure pour les conseils aux couples) qui peuvent être remboursées par le biais de votre assurance maladie paramédicale, compte de dépenses de santé ou payées en privé.',
    dependants: true,
  },
  {
    marketingURL: 'challengergeomatics',
    path: 'challengergeomatics',
    title: 'Challenger GeoMatics',
    logo: cglLogo,
    logoClass: 'corporate-nav-logo',
    full: true,
    email_domain: ['challengergeomatics.com', 'inkblottherapy.com'],
    dependants: true,
  },
  {
    marketingURL: 'davies',
    path: 'davies',
    title: 'Davies',
    logo: daviesLogo,
    logoClass: 'corporate-nav-logo',
    full: true,
    email_domain: ['dwpv.com', 'inkblottherapy.com'],
    dependants: true,
    showFlags: true,
    frenchPage: true,
    removeSecondParagraph: COUNTRY === 'US' && true,
    usNotFree: true,
    subsequentSessionsFr: 'Pour les employés canadiens, Les séances individuelles subséquentes ne coûtent que 75 $ / heure (120 $ / heure pour les conseils aux couples) qui peuvent être remboursées par le biais de votre assurance maladie paramédicale, compte de dépenses de santé ou payées en privé. Pour les employés américaine, les séances individuelles ne coûtent que 90 $ / heure et peuvent être payées par votre assureur maladie ou payées en privé.',
    subsequentSessions: 'For Canadian Team Members, individual sessions cost just $75/hour which may be reimbursable through your paramedical health benefit or paid privately. For US Team Members, individual sessions cost just $90/hour and may be paid out through your health insurer or paid privately.',
  },
  {
    marketingURL: 'kingcoleducks',
    path: 'kingcoleducks',
    title: 'King Cole Ducks',
    logo: kingcoleducksLogo,
    logoClass: 'key-living-logo',
    dependants: true,
    partial: true,
  },
  {
    marketingURL: 'maxpeople',
    path: 'maxpeople',
    title: 'MaxPeople',
    logo: maxPeopleLogo,
    logoClass: 'key-living-logo',
    subsequentSessions: 'Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be reimbursable through your HSA or paid privately.',
  },
  {
    marketingURL: 'vistarmedia',
    path: 'vistarmedia',
    title: 'Vistar Media',
    logo: vistarLogo,
    logoClass: 'corporate-nav-logo',
    subsequentSessions: 'US employees can continue with sessions paid out using your UnitedHealthcare insurance. The rates will be in alignment with your benefits plan for in network mental health visits. For Canadian employees, subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be covered by your private health insurance, benefits coverage or paid privately.',
    dependants: true,
  },
  {
    marketingURL: 'tebco',
    path: 'tebco',
    title: 'TEB/CO',
    logo: tebcoLogo,
    logoClass: 'corporate-nav-logo',
  },
  {
    marketingURL: 'sinkingship',
    path: 'sinkingship',
    title: 'Sinking Ship',
    logo: sinkingShipLogo,
    logoClass: 'key-living-logo',
    dependants: true,
    full: true,
    email_domain: ['inkblottherapy.com', 'sinkingship.ca'],
    subsequentSessions: 'Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be covered by your private health insurance, benefits coverage or paid privately.',
  },
  {
    marketingURL: 'sci',
    path: 'sci',
    title: 'SCI Ltd',
    logo: sciLtdLogo,
    secondLogo: sciLtdLogo2,
    logoClass: 'corporate-nav-logo',
    email_domain: ['scileasecorp.com', 'scimarketview.com', 'inkblottherapy.com'],
    full: true,
    dependants: true,
  },
  {
    marketingURL: 'newtopia',
    path: 'newtopia',
    title: 'Newtopia',
    logo: newtopiaLogo,
    logoClass: 'corporate-nav-logo',
    email_domain: ['newtopia.com', 'inkblottherapy.com'],
    full: true,
    dependants: true,
  },
  {
    marketingURL: 'discoverydental',
    path: 'discoverydental',
    title: 'Discovery Dental',
    logo: discoveryDentalLogo,
    logoClass: 'corporate-nav-logo',
    dependants: true,
    partial: true,
  },
  {
    marketingURL: 'fortress',
    path: 'fortress',
    title: 'Fortress Technology',
    logo: fortressLogo,
    logoClass: 'corporate-nav-logo',
    partial: true,
    dependants: true,
  },
  {
    marketingURL: 'ioof',
    path: 'ioof',
    title: 'IOOF Senior Homes',
    logo: ioofLogo,
    logoClass: 'key-living-logo',
    partial: true,
    dependants: true,
    subsequentSessions: 'Inkblot offers convenient and confidential video counselling any time, any place. Simply choose from the most effective counsellors based on your needs and preferences and book a first free session to ensure compatibility. Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) and may be reimbursable under your paramedical health benefit or paid privately.',
  },
  {
    marketingURL: 'timbercreek',
    path: 'timbercreek',
    title: 'Hazelview',
    logo: hvInvestmentsLogo,
    secondLogo: hvPropertiesLogo,
    logoClass: 'key-living-logo',
    full: true,
    dependants: true,
    subsequentSessions: 'Inkblot offers convenient and confidential video counselling any time, any place. Simply choose from the most effective counsellors based on your needs and preferences and book a first free session to ensure compatibility. Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) and may be reimbursable under your paramedical health benefit or paid privately.',
    subsequentSessionsFr: 'Inkblot offre des conseils vidéo pratiques et confidentiels à tout moment et en tout lieu. Choisissez simplement parmi les conseillers les plus efficaces en fonction de vos besoins et préférences et réservez une première session gratuite pour assurer la compatibilité. Les séances subséquentes ne coûtent que 75 $ / heure pour le counseling individuel (120 $ / heure couple / famille) et peuvent être remboursables au titre de votre assurance maladie paramédicale ou payées en privé.',
    frenchPage: true,
    email_domain: ['inkblottherapy.com', 'timbercreek.com'],
  },
  {
    marketingURL: 'csa',
    path: 'csa',
    title: 'CSA Group',
    frTitle: 'Groupe CSA',
    logo: csaLogo,
    frLogo: csaLogoFr,
    logoClass: 'corporate-nav-logo',
    full: true,
    dependants: true,
    subsequentSessions: 'Inkblot offers convenient and confidential video counselling any time, any place. Simply choose from the most effective counsellors based on your needs and preferences and book a first free session to ensure compatibility. Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) and may be reimbursable under your paramedical health benefit or paid privately.',
    subsequentSessionsFr: 'Inkblot offre des conseils vidéo pratiques et confidentiels à tout moment et en tout lieu. Choisissez simplement parmi les conseillers les plus efficaces en fonction de vos besoins et préférences et réservez une première session gratuite pour assurer la compatibilité. Les séances subséquentes ne coûtent que 75 $ / heure pour le counseling individuel (120 $ / heure couple / famille) et peuvent être remboursables au titre de votre assurance maladie paramédicale ou payées en privé.',
    frenchPage: true,
    email_domain: ['inkblottherapy.com', 'csagroup.org'],
  },
  {
    marketingURL: 'seamlessmd',
    path: 'seamlessmd',
    title: 'Seamless MD',
    logo: seamlessLogo,
    logoClass: 'corporate-nav-logo',
  },
  {
    marketingURL: 'ibigroup',
    path: 'ibigroup',
    title: 'IBI Group',
    logo: ibigroupLogo,
    logoClass: 'key-living-logo',
    dependants: true,
    full: true,
    email_domain: ['inkblottherapy.com', 'ibigroup.com'],
  },
  {
    marketingURL: 'convene',
    path: 'convene',
    title: 'Convene',
    logo: conveneLogo,
    logoClass: 'corporate-nav-logo',
    dependants: true,
    full: true,
    email_domain: ['inkblottherapy.com', 'convene.com'],
    subsequentSessions: 'Employees can continue with sessions paid out using your UnitedHealthcare insurance. The rates will be in alignment with your benefits plan for in network mental health visits.',
    usOnly: true,
  },
  {
    marketingURL: 'iqoffices',
    path: 'iqoffices',
    title: 'IQ Offices',
    logo: iqOfficesLogo,
    logoClass: 'corporate-nav-logo',
  },
  {
    marketingURL: 'bci',
    path: 'bci',
    title: 'BCI',
    logo: bciLogo,
    logoClass: 'corporate-nav-logo',
    dependants: true,
    full: true,
    email_domain: ['inkblottherapy.com', 'bci.ca'],
    subsequentSessions: 'Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be covered by your private health insurance, benefits coverage or paid privately.',
  },
  {
    marketingURL: 'duttonbrock',
    path: 'duttonbrock',
    title: 'Dutton Brock',
    logo: duttonBrockLogo,
    logoClass: 'corporate-nav-logo',
  },
  {
    marketingURL: 'questica',
    path: 'questica',
    title: 'Questica',
    logo: questicaLogo,
    logoClass: 'key-living-logo',
    subsequentSessions: 'For Canadian employees, subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be reimbursable through your paramedical health benefit or paid privately. For US employees, subsequent sessions can be paid out using your Aetna insurance. The rates will be in alignment with your benefits plan for in network mental health visits.',
    email_domain: ['inkblottherapy.com', 'questica.com'],
    full: true,
  },
  {
    marketingURL: 'morguard',
    path: 'morguard',
    title: 'Morguard',
    logo: morguardLogo,
    logoClass: 'corporate-nav-logo',
    frenchPage: true,
    email_domain: ['inkblottherapy.com', 'morguard.com'],
    dependants: true,
    subsequentSessions: 'Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be reimbursable through your paramedical health benefit or paid privately.',
    subsequentSessionsFr: 'Les séances individuelles subséquentes ne coûtent que 75 $ / heure (120 $ / heure pour les conseils aux couples) qui peuvent être remboursées par le biais de votre assurance maladie paramédicale, compte de dépenses de santé ou payées en privé.',
    full: true,
  },
  {
    marketingURL: 'diamond',
    path: 'diamond',
    title: 'Diamond',
    logo: diamondLogo,
    logoClass: 'corporate-nav-logo',
    frenchPage: true,
    subsequentSessions: 'Inkblot offers convenient and confidential video counselling any time, any place. Simply choose from the most effective counsellors based on your needs and preferences and book a first free session to ensure compatibility. Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) and may be reimbursable under your paramedical health benefit.',
    subsequentSessionsFr: 'Inkblot offre un service de vidéoconsultation pratique et confidentiel, partout, en tout temps. Il suffit de choisir parmi les thérapeutes qui correspondent le mieux à vos besoins et à vos préférences, puis de réserver votre première séance gratuite, afin d’assurer votre compatibilité. Les séances suivantes ne coûtent que 37,50 $ par demi-heure et elles peuvent être remboursées par vos avantages sociaux, pour les soins paramédicaux.',
    dependants: true,
  },
  {
    marketingURL: 'sleepcountry',
    path: 'sleepcountry',
    title: 'Sleep Country',
    logo: sleepCountryLogo,
    logoClass: 'corporate-nav-logo',
    frenchPage: true,
    subsequentSessions: 'Inkblot offers convenient and confidential video counselling any time, any place. Simply choose from the most effective counsellors based on your needs and preferences and book a first free session to ensure compatibility. Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) and may be reimbursable under your paramedical health benefit.',
    subsequentSessionsFr: 'Inkblot offre des conseils vidéo pratiques et confidentiels à tout moment et en tout lieu. Choisissez simplement parmi les conseillers les plus efficaces en fonction de vos besoins et préférences et réservez une première session gratuite pour assurer la compatibilité. Les séances suivantes ne coûtent que 75 $ / heure pour le counseling individuel (120 $ / heure couple / famille) et peuvent être remboursables au titre de votre prestation de soins paramédicaux.',
    dependants: true,
    full: true,
    email_domain: ['inkblottherapy.com', 'sleepcountry.ca', 'dormezvous.com'],

  },
  {
    marketingURL: 'tps',
    path: 'tps',
    title: 'Toronto Police Service',
    logo: tpsLogo,
    logoClass: 'key-living-logo',
    frenchPage: true,
    subsequentSessions: 'Inkblot offers convenient and confidential video counselling any time, any place. Simply choose from the most effective counsellors based on your needs and preferences and book a first free session to ensure compatibility. Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) and may be reimbursable under your paramedical health benefit if you are eligible, or paid privately.',
    subsequentSessionsFr: 'Inkblot offre des conseils vidéo pratiques et confidentiels à tout moment et en tout lieu. Choisissez simplement parmi les conseillers les plus efficaces en fonction de vos besoins et préférences et réservez une première session gratuite pour assurer la compatibilité. Les séances suivantes ne coûtent que 75 $ / heure pour le counseling individuel (120 $ / heure couple / famille) et peuvent être remboursables au titre de votre prestation de soins paramédicaux.',
    dependants: true,
    email_domain: ['inkblottherapy.com', 'torontopolice.on.ca'],
  },
  {
    marketingURL: 'dmz',
    path: 'dmz',
    title: 'DMZ',
    logo: dmzLogo,
    logoClass: 'corporate-nav-logo',
    unlimitedSessions: 'Your mental health matters and that’s why DMZ and Inkblot have partnered to provide you with confidential and convenient video counselling and mental health support at no cost.',
    isDMZ: true,
  },
  {
    marketingURL: 'eastcoaststrong',
    path: 'eastcoaststrong',
    title: 'Gallivan',
    logo: mywellnessLogo,
    logoClass: 'key-living-logo',
    unlimitedSessions: 'As leaders in mental health support for students across Canada through our mywellness program, Gallivan has partnered with Inkblot to bring affordable Online Video Counselling to ALL students who may need the support.',
  },
  {
    marketingURL: 'league',
    path: 'league',
    title: 'League',
    logo: leagueLogo,
    logoClass: 'corporate-nav-logo',
    unlimitedSessions: 'Your mental health matters and that’s why League and Inkblot have partnered to provide you with confidential and convenient video counselling and mental health support at no cost.',
    unlimitedSessionsFr: 'Votre santé mentale est importante c’est pourquoi League s’est associée à Inkblot, afin de vous offrir des vidéoconsultations et du soutien en matière de santé mentale, en toute confidentialité et convivialité.',
    frenchPage: true,
  },
  {
    marketingURL: 'indigo',
    path: 'indigo',
    title: 'Indigo',
    logo: indigoLogo,
    logoClass: 'corporate-nav-logo',
    unlimitedSessions: 'With Inkblot, mental health support is completely confidential and just a click away. At half the cost of traditional therapy, your benefits will cover 25 sessions!',
  },
  {
    marketingURL: 'uber',
    path: 'uber',
    title: 'Uber',
    logo: uberLogo,
    logoClass: 'corporate-nav-logo',
    unlimitedSessions: 'Your mental health matters and that’s why Uber and Inkblot have partnered to provide you with confidential and convenient video counselling and mental health support.',
    unlimitedSessionsFr: 'Votre santé mentale est importante, c’est pourquoi Uber s’est associée à Inkblot, afin de vous offrir des vidéoconsultations et du soutien en matière de santé mentale, en toute confidentialité et convivialité.',
    frenchPage: true,
    dependants: true,
  },
  {
    marketingURL: 'bonfire',
    path: 'bonfire',
    title: 'Bonfire',
    logo: bonfireLogo,
    logoClass: 'corporate-nav-logo',
    full: true,
    email_domain: ['gobonfire.com', 'inkblottherapy.com'],
  },
  {
    marketingURL: 'cdlsi',
    path: 'cdlsi',
    title: 'CDLSI',
    logo: cdlsiLogo,
    logoClass: 'key-living-logo',
    full: true,
    email_domain: ['cdlsi.com', 'inkblottherapy.com'],
    dependants: true,
  },
  {
    marketingURL: 'baycrestgc',
    path: 'baycrestgc',
    title: 'Baycrest Construction',
    logo: baycrestLogo,
    logoClass: 'key-living-logo',
    full: true,
    email_domain: ['baycrestgc.com', 'inkblottherapy.com'],
    dependants: true,
  },
  {
    marketingURL: 'appcentrica',
    path: 'appcentrica',
    title: 'Appcentrica',
    logo: appcentricaLogo,
    logoClass: 'corporate-nav-logo',
    full: true,
    email_domain: ['appcentrica.com', 'inkblottherapy.com'],
  },
  {
    marketingURL: 'securitycompass',
    path: 'securitycompass',
    title: 'Security Compass',
    logo: securitycompassLogo,
    logoClass: 'corporate-nav-logo',
    full: true,
    email_domain: ['securitycompass.com', 'inkblottherapy.com'],
    dependants: true,
    subsequentSessions: 'For Canadian employees, subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be covered by your private health insurance, benefits coverage or paid privately. US employees can continue with sessions paid out using your health insurance. The rates will be in alignment with your benefits plan for in network mental health visits.',
    showFlags: true,
  },
  {
    marketingURL: 'varicent',
    path: 'varicent',
    title: 'Varicent',
    logo: varicentLogo,
    logoClass: 'corporate-nav-logo',
    dependants: true,
    full: true,
    email_domain: ['varicent.com', 'inkblottherapy.com'],
    showFlags: true,
    removeSecondParagraph: true,
    subsequentSessions: 'For Canadian Team Members, subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be reimbursable through your paramedical health benefit or paid privately. For US Team Members, individual sessions cost just $90/hour ($144/hour for couples counselling) and may be paid out through your health insurer or paid privately.',
  },
  {
    marketingURL: 'intellijointsurgical',
    path: 'intellijointsurgical',
    title: 'Intellijoint Surgical',
    logo: intellijointLogo,
    logoClass: 'corporate-nav-logo',
    dependants: true,
    full: true,
    email_domain: ['intellijointsurgical.com', 'inkblottherapy.com'],
    showFlags: true,
    removeSecondParagraph: true,
    subsequentSessions: 'For Canadian Team Members, individual sessions cost just $75/hour ($120/hour for couples counselling) which may be reimbursable through your paramedical health benefit or paid privately. For US Team Members, individual sessions cost just $90/hour ($144/hour for couples counselling) and may be paid out through your health insurer or paid privately.',
  },
  {
    marketingURL: 'sensibill',
    path: 'sensibill',
    title: 'Sensibill',
    logo: sensibillLogo,
    logoClass: 'key-living-logo',
    partial: true,
    email_domain: ['getsensibill.com', 'inkblottherapy.com'],
    dependants: true,
    showFlags: true,
    subsequentSessions: 'For Canadian Team Members, individual sessions cost just $75/hour ($120/hour for couples counselling) which may be reimbursable through your paramedical health benefit or paid privately. For US Team Members, individual sessions cost just $90/hour ($144/hour for couples counselling) and may be paid out through your health insurer or paid privately.',
  },
  {
    marketingURL: 'alliancefranchise',
    path: 'alliancefranchise',
    title: 'Alliance Franchise Brands',
    logo: alliancefranchiseLogo,
    logoClass: 'corporate-nav-logo',
    partial: true,
    dependants: true,
    showFlags: true,
    subsequentSessions: "For Canadian employees, subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be covered by your (or a spouse's) health insurance or paid privately. For US employees, subsequent individual sessions cost just $90/hour ($144/hour for couples counselling) which may be covered by your (or a spouse's) health insurance or paid privately.",
    removeSecondParagraph: true,
  },
  {
    marketingURL: 'nca',
    path: 'nca',
    title: 'National Concrete Accessories',
    logo: ncaLogo,
    logoClass: 'corporate-nav-logo',
    partial: true,
    dependants: true,
    frenchPage: true,
    subsequentSessions: 'Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be reimbursable through your paramedical health benefit or paid privately.',
    subsequentSessionsFr: 'Les séances individuelles subséquentes ne coûtent que 75 $ / heure (120 $ / heure pour les conseils aux couples) qui peuvent être remboursées par le biais de votre assurance maladie paramédicale, compte de dépenses de santé ou payées en privé.',
  },
  {
    marketingURL: 'rb',
    path: 'rb',
    title: 'Reckitt Benckiser',
    logo: rbLogo,
    logoClass: 'key-living-logo',
    full: true,
    email_domain: ['rb.com', 'inkblottherapy.com'],
    dependants: true,
    frenchPage: true,
    subsequentSessions: 'Inkblot offers convenient and confidential video counselling any time, any place. Simply choose from the most effective counsellors based on your needs and preferences and book a first free session to ensure compatibility. Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) and may be reimbursable under your paramedical health benefit or paid privately.',
    subsequentSessionsFr: 'Les séances individuelles subséquentes ne coûtent que 75 $ / heure (120 $ / heure pour les conseils aux couples) qui peuvent être remboursées par le biais de votre assurance maladie paramédicale, compte de dépenses de santé ou payées en privé.',
  },
  {
    marketingURL: 'einc',
    path: 'einc',
    title: 'E Inc.',
    logo: edealerLogo,
    logoClass: 'key-living-logo',
    full: true,
    email_domain: ['eblock.com', 'edealer.ca', 'eblock.ca', 'inkblottherapy.com'],
    dependants: true,
    showFlags: true,
    subsequentSessions: 'For Canadian employees, subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be covered by your private health insurance, benefits coverage or paid privately. US employees can continue with sessions paid out using your health insurance . The rates will be in alignment with your benefits plan for in network mental health visits.',
  },
  {
    marketingURL: 'cosmo',
    path: 'cosmo',
    title: 'Cosmo Music',
    logo: cosmoLogo,
    logoClass: 'key-living-logo',
    partial: true,
    dependants: true,
  },
  {
    marketingURL: 'valleyview',
    path: 'valleyview',
    title: 'Valleyview Residence',
    logo: valleyviewLogo,
    logoClass: 'key-living-logo',
    subsequentSessions: 'Inkblot offers convenient and confidential video counselling any time, any place. Simply choose from the most effective counsellors based on your needs and preferences and book a first free session to ensure compatibility. Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) and may be reimbursable under your paramedical health benefit or paid privately.',
    noneSignup: true,
    dependants: true,
  },
  {
    marketingURL: 'forestview',
    path: 'forestview',
    title: 'Forestview Retirement Residence',
    logo: forestviewLogo,
    logoClass: 'key-living-logo',
    subsequentSessions: 'Inkblot offers convenient and confidential video counselling any time, any place. Simply choose from the most effective counsellors based on your needs and preferences and book a first free session to ensure compatibility. Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) and may be reimbursable under your paramedical health benefit or paid privately.',
    noneSignup: true,
    dependants: true,
  },
  {
    marketingURL: 'trillium',
    path: 'trillium',
    title: 'Trillium Health Partners',
    logo: trilliumLogo,
    logoClass: 'key-living-logo',
    subsequentSessions: 'Inkblot offers convenient and confidential video counselling any time, any place. Simply choose from the most effective counsellors based on your needs and preferences and book a first free session to ensure compatibility. Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) and may be reimbursable under your paramedical health benefit or paid privately.',
    noneSignup: true,
    dependants: true,
  },
  {
    marketingURL: 'citron',
    path: 'citron',
    title: 'Citron Hygiene',
    logo: citronLogo,
    logoClass: 'corporate-nav-logo',
    full: true,
    subsequentSessions: 'Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be reimbursable under your paramedical health coverage or paid privately.',
    subsequentSessionsFr: 'Les séances individuelles subséquentes ne coûtent que 75 $ / heure (120 $ / heure pour les conseils aux couples) qui peuvent être remboursées par le biais de votre assurance maladie paramédicale ou payées en privé.',
    dependants: true,
    email_domain: ['citronhygiene.com', 'inkblottherapy.com'],
    frenchPage: true,
  },
  {
    marketingURL: 'well',
    path: 'well',
    title: 'Well',
    logo: wellLogo,
    logoClass: 'corporate-nav-logo',
  },
  {
    marketingURL: 'partner',
    path: 'partner',
    title: 'Your Company',
    logo: partnerLogo,
    logoClass: 'partner-logo',
  },
  // Access Plus Company template
  // {
  //   path: 'path', // This is the same as the customized_url in the database
  //   marketingURL: 'path', // Usually the same as the path but can be customized for companies with multiple portal pages
  //   title: 'Title', // Name of the company as shown on Landing page
  //   logo: companyLogo, // Logo imported from above
  //   logoClass: 'class-name', // Use 'corporate-nav-logo' for wider, rectangular logos and 'key-living-logo' for smaller, square or circular logos
  //   dependants: true, // True if company includes dependants
  //   subsequentSessions: 'Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be covered by your private health insurance, benefits coverage or paid privately.', // To be customized if the last paragraph is different than the default
  //   minutes: '3 hours', // This is where you put the time alotted in the contract - ex. '2 hours' or '120 minutes'
  //   frenchPage: true, // True if English and French
  //   subsequentSessionsFr: 'Les séances individuelles subséquentes ne coûtent que 75 $ / heure (120 $ / heure pour les conseils aux couples) qui peuvent être remboursées par le biais de votre assurance maladie paramédicale ou payées en privé.', // Needs to be customized if frenchPage: true,
  //   heures: '3 prochaines heures', // This is where you put the time alotted in the contract in french - ex. '2 prochaines heures' or '120 prochaines minutes'
  //   noneSignup: true, // True if portal gating is None
  //   partial: true, // True if portal gating is Partial
  //   full: true, // True if portal gating is Full
  //   email_domain: ['inkblottherapy.com', 'url.com'], // For Full portal gating use Inkblot url for testing and company url based on eligibility list
  // },
  // Copy the template below to start
  // {
  //   path: 'path',
  //   marketingURL: 'path',
  //   title: 'Title',
  //   logo: companyLogo,
  //   logoClass: 'corporate-nav-logo',
  //   dependants: true,
  //   minutes: '2 hours',
  //   full: true,
  //   email_domain: ['inkblottherapy.com', 'url.com'],
  // },
  {
    path: 'wirthhats',
    marketingURL: 'wirthhats',
    title: 'WIRTH Hats',
    logo: wirthLogo,
    logoClass: 'key-living-logo',
    minutes: '2 hours',
    fullyGated: true,
    frenchPage: true,
    heures: '2 prochaines heures',
    subsequentSessionsFr: 'Les séances individuelles subséquentes ne coûtent que 75 $ / heure (120 $ / heure pour les conseils aux couples) qui peuvent être remboursées par le biais de votre assurance maladie paramédicale ou payées en privé.',
  },
  {
    path: 'omvic',
    marketingURL: 'omvic',
    title: 'OMVIC',
    logo: omvicLogo,
    logoClass: 'key-living-logo',
    dependants: true,
    minutes: '120 minutes',
    full: true,
    email_domain: ['inkblottherapy.com', 'omvic.on.ca'],
  },
  {
    path: 'vela',
    marketingURL: 'vela',
    title: 'VELA Wealth',
    logo: velaLogo,
    logoClass: 'corporate-nav-logo',
    dependants: true,
    minutes: '4 hours',
    full: true,
    email_domain: ['inkblottherapy.com', 'velawealth.com'],
  },
  {
    path: 'kasian',
    marketingURL: 'kasian',
    title: 'Kasian',
    logo: kasianLogo,
    logoClass: 'key-living-logo',
    dependants: true,
    minutes: '2 hours',
    full: true,
    email_domain: ['inkblottherapy.com', 'kasian.com/'],
  },
  {
    marketingURL: 'hearthstone',
    path: 'hearthstone',
    title: 'Hearthstone Communities',
    logo: hearthstoneLogo,
    logoClass: 'corporate-nav-logo',
    minutes: '3 hours',
    subsequentSessions: "Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be covered by your (or a spouse's) health insurance or paid privately.",
    partial: true,
  },
  {
    marketingURL: 'esg',
    path: 'esg',
    title: 'East Side Games',
    logo: esgLogo,
    logoClass: 'corporate-nav-logo',
    minutes: '90 minutes',
    subsequentSessions: 'After that, you can continue counselling with sessions reimbursable through your paramedical health coverage. Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling).',
    email_domain: ['eastsidegamesstudio.com', 'idrlygames.io', 'inkblottherapy.com'],
    full: true,
  },
  {
    marketingURL: 'loopio',
    path: 'loopio',
    title: 'Loopio',
    logo: loopioLogo,
    logoClass: 'corporate-nav-logo',
    minutes: '5 hours',
    full: true,
    email_domain: ['loopio.com', 'inkblottherapy.com'],
    dependants: true,
    subsequentSessions: "Dependents' next 2.5 hours of counselling per year are covered. Subsequent individual sessions cost just $75/hour ($120/ hour for couples counselling) and reimbursable through your League Health Spending Account, Lifestyle Spending Account or paramedical health coverage. You’ll find your Inkblot card quickly and easily in your League digital wallet.",
  },
  {
    marketingURL: 'eastsidegames',
    path: 'esg',
    title: 'East Side Games',
    logo: esgLogo,
    logoClass: 'corporate-nav-logo',
    minutes: '90 minutes',
    subsequentSessions: 'After that, you can continue counselling with sessions reimbursable through your paramedical health coverage. Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling).',
    email_domain: ['eastsidegamesstudio.com', 'idrlygames.io', 'inkblottherapy.com'],
    full: true,
  },
  {
    marketingURL: 'genuinehealth',
    path: 'genuinehealth',
    title: 'Genuine Health',
    logo: genuineHealthLogo,
    logoClass: 'corporate-nav-logo',
    minutes: '90 minutes',
    subsequentSessions: 'After that, you can continue with counselling and use your League Health Spending Account, Lifestyle Spending Account or Great West Life coverage. Inkblot’s rates are $37.50 per half-hour, $75 per hour, and $112.50 for 90 minutes. You’ll find your Inkblot card quickly and easily in your League digital wallet.',
  },
  {
    marketingURL: 'redox',
    path: 'redox',
    title: 'Redox',
    logo: redoxLogo,
    logoClass: 'corporate-nav-logo',
    minutes: '90 minutes',
    subsequentSessions: 'Subsequent sessions are reimbursable through your Health Savings Account.',
  },
  {
    marketingURL: 'thesweetpotato',
    path: 'thesweetpotato',
    title: 'The Sweet Potato',
    logo: tspLogo,
    logoClass: 'corporate-nav-logo',
    minutes: '2 hours',
    subsequentSessions: 'Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be covered by your Health Spending Account or paid privately.',
  },
  {
    marketingURL: 'ratiocity',
    path: 'ratiocity',
    title: 'Ratio City',
    logo: ratioLogo,
    logoClass: 'key-living-logo',
    minutes: '3 hours',
    subsequentSessions: 'Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be paid privately.',
  },
  {
    marketingURL: 'afilias',
    path: 'afilias',
    title: 'Afilias',
    logo: afiliasLogo,
    logoClass: 'corporate-nav-logo',
    minutes: '5 hours',
    subsequentSessions: 'Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be reimbursed through your paramedical benefits or paid privately.',
  },
  {
    marketingURL: 'cbn',
    path: 'cbn',
    title: 'CBN',
    logo: cbnLogo,
    secondLogo: cbnLogo2,
    logoClass: 'key-living-logo',
    minutes: '3 hours',
    subsequentSessions: 'Subsequent individual sessions cost just $45USD/half hour ($72USD/half hour for couples counselling) which may be reimbursed through your health insurance or paid privately.',
  },
  {
    marketingURL: 'tucows',
    path: 'tucows',
    title: 'Tucows',
    logo: tucowsLogo,
    logoClass: 'corporate-nav-logo',
    minutes: '90 minutes',
    subsequentSessions: 'After that, Canadian employees can continue with counselling and use your League Health Spending Account, Lifestyle Spending Account or Desjardins coverage. Inkblot’s rates are $37.50 per half-hour, $75 per hour, and $112.50 for 90 minutes. US Employees can continue with sessions paid out using your Health Spending Account or Lifestyle Spending Account. The rates will be in alignment with your benefits plan for in network mental health visits.',
  },
  {
    marketingURL: 'athinkingape',
    path: 'athinkingape',
    title: 'A Thinking Ape',
    logo: thinkingApeLogo,
    logoClass: 'corporate-nav-logo',
    minutes: '3 hours',
    email_domain: ['inkblottherapy.com', 'athinkingape.com'],
    full: true,
    dependants: true,
  },
  {
    marketingURL: 'reunion',
    path: 'reunion',
    title: 'Reunion Inc',
    logo: reunionLogo,
    logoClass: 'corporate-nav-logo',
    minutes: '2 hours',
  },
  {
    marketingURL: 'flipgive',
    path: 'flipgive',
    title: 'FlipGive',
    logo: flipgiveLogo,
    logoClass: 'corporate-nav-logo',
    minutes: '3 hours',
  },
  {
    marketingURL: 'tribalscale',
    path: 'tribalscale',
    title: 'TribalScale',
    logo: tribalscaleLogo,
    logoClass: 'corporate-nav-logo',
    minutes: '5 hours',
  },
  {
    marketingURL: 'dream',
    path: 'dream',
    title: 'Dream',
    logo: dreamLogo,
    logoClass: 'corporate-nav-logo',
    minutes: '90 minutes',
    subsequentSessions: 'After that, you can continue with counselling and use your League Health Spending Account, Lifestyle Spending Account or paramedical health coverage. Inkblot’s rates are $37.50 per half-hour, $75 per hour, and $112.50 for 90 minutes. You’ll find your Inkblot card quickly and easily in your League digital wallet.',
    noneSignup: true,
  },
  {
    marketingURL: 'campbrain',
    path: 'campbrain',
    title: 'CampBrain',
    logo: campbrainLogo,
    logoClass: 'corporate-nav-logo',
    full: true,
    email_domain: ['campbrain.com', 'inkblottherapy.com'],
    dependants: true,
    minutes: '2 hours',
    subsequentSessions: 'Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be covered by your HSA or paid privately.',
  },
  {
    marketingURL: 'kirasystems',
    path: 'kirasystems',
    title: 'Kira Systems',
    logo: kiraSystemsLogo,
    logoClass: 'corporate-nav-logo',
    full: true,
    email_domain: ['kirasystems.com', 'inkblottherapy.com'],
    dependants: true,
    minutes: '60 minutes',
    subsequentSessions: 'For Canadian employees, subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be covered by your private health insurance, benefits coverage or paid privately. US employees can continue with sessions paid out using your health insurance. The rates will be in alignment with your benefits plan for in network mental health visits.',
    showFlags: true,
  },
  {
    marketingURL: 'velocity',
    path: 'velocity',
    title: 'Velocity',
    logo: velocityLogo,
    logoClass: 'corporate-nav-logo',
    minutes: '90 minutes',
  },
  {
    marketingURL: 'key',
    path: 'key',
    title: 'Key Living',
    logo: keyLivingLogo,
    logoClass: 'corporate-nav-logo',
    minutes: '30 minutes',
    full: true,
    email_domain: ['keyliving.com', 'inkblottherapy.com'],
    subsequentSessions: 'Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be covered by your private health insurance, benefits coverage or paid privately.',
  },
  {
    marketingURL: 'lyricfind',
    path: 'lyricfind',
    title: 'LyricFind',
    logo: lyricfindLogo,
    logoClass: 'corporate-nav-logo',
    minutes: '60 minutes',
  },
  {
    marketingURL: 'streetcontxt',
    path: 'streetcontxt',
    title: 'Street Contxt',
    logo: streetContxtLogo,
    logoClass: 'corporate-nav-logo',
    minutes: '2 hours',
  },
  {
    marketingURL: 'carbon60',
    path: 'carbon60',
    title: 'Carbon60',
    logo: carbon60Logo,
    logoClass: 'corporate-nav-logo',
    minutes: '2 hours',
    full: true,
    email_domain: ['carbon60.com', 'inkblottherapy.com'],
  },
  {
    marketingURL: 'blanclabs',
    path: 'blanclabs',
    title: 'Blanc Labs',
    logo: blanclabsLogo,
    logoClass: 'corporate-nav-logo',
    minutes: '2 hours',
    subsequentSessions: 'Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be covered by your private health insurance, benefits coverage or paid privately.',
    dependants: true,
    full: true,
    email_domain: ['blanclabs.com', 'inkblottherapy.com'],
  },
  {
    marketingURL: 'hoem',
    path: 'hoem',
    title: 'HOEM on Jarvis',
    logo: hoemLogo,
    logoClass: 'key-living-logo',
    minutes: '2 hours',
    subsequentSessions: 'Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be covered by your student health insurance or paid privately.',
    full: true,
    student: true,
    email_domain: ['hoem.ca', 'inkblottherapy.com'],
    annuallyCovered: false,
  },
  {
    marketingURL: 'mapleleaffoods',
    path: 'mapleleaffoods',
    title: 'Maple Leaf Foods',
    logo: mapleleaffoodsLogo,
    logoClass: 'key-living-logo',
    frenchPage: true,
    minutes: '10 hours',
    heures: '10 prochaines heures',
    subsequentSessions: 'Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be reimbursable through your paramedical health benefit, Health Spending Account (HSA) or paid privately.',
    subsequentSessionsFr: 'Les séances individuelles subséquentes ne coûtent que 75 $ / demi-heure (120 $ / demi-heure pour les conseils aux couples) qui peuvent être remboursées par le biais de votre assurance maladie paramédicale, compte de dépenses de santé ou payées en privé.',
  },
  {
    marketingURL: 'canadalearningcode',
    path: 'canadalearningcode',
    title: 'Canada Learning Code',
    logo: canadalearningcodeLogo,
    logoClass: 'key-living-logo',
    frenchPage: true,
    minutes: '3 hours',
    heures: '3 prochaines heures',
    subsequentSessions: 'Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be reimbursable through your paramedical health benefit or paid privately.',
    subsequentSessionsFr: 'Les séances individuelles subséquentes ne coûtent que 75 $ / heure (120 $ / heure pour les conseils aux couples) qui peuvent être remboursées par le biais de votre assurance maladie paramédicale ou payées en privé.',
    full: true,
    email_domain: ['inkblottherapy.com', 'canadalearningcode.ca'],
    dependants: true,
  },
  {
    marketingURL: 'endy',
    path: 'endy',
    title: 'Endy',
    logo: endyLogo,
    logoClass: 'key-living-logo',
    frenchPage: true,
    minutes: '5 hours',
    heures: '5 prochaines heures',
    subsequentSessions: "Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be covered by your (or a spouse's) health insurance or paid privately.",
    subsequentSessionsFr: 'Les séances individuelles subséquentes ne coûtent que 75 $ / heure (120 $ / heure pour les conseils aux couples) qui peuvent être remboursées par le biais de votre assurance maladie paramédicale ou payées en privé.',
    dependants: true,
    full: true,
    email_domain: ['inkblottherapy.com', 'endy.com'],
  },
  {
    marketingURL: 'canadianstage',
    path: 'canadianstage',
    title: 'Canadian Stage',
    logo: csLogo,
    logoClass: 'corporate-nav-logo',
    subsequentSessions: "Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be covered by your (or a spouse's) health insurance or paid privately.",
    full: true,
    email_domain: ['inkblottherapy.com', 'canadianstage.com'],
    minutes: '60 minutes',
  },
  {
    marketingURL: 'unifiedalloys',
    path: 'unifiedalloys',
    title: 'Unified Alloys',
    logo: unifiedAlloysLogo,
    logoClass: 'key-living-logo',
    full: true,
    email_domain: ['unifiedalloys.com', 'inkblottherapy.com'],
    dependants: true,
    minutes: '3 hours',
  },
  {
    marketingURL: 'bbfsupport',
    path: 'bbfsupport',
    title: 'Bartenders Benevolent Fund',
    logo: bbfLogo,
    logoClass: 'key-living-logo',
    partial: true,
    minutes: '60 minutes',
    subsequentSessions: 'Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which can be paid privately or covered by private health insurance should you have.',
  },
  {
    marketingURL: 'insight',
    path: 'insight',
    title: 'Insight TV',
    logo: insightLogo,
    logoClass: 'key-living-logo',
    partial: true,
    showFlags: true,
    minutes: '2 hours',
    subsequentSessions: 'Your initial 30-minute individual session is complimentary. For the next four months, Insight TV will cover 2 hours of counselling.',
    removeSecondParagraph: true,
    finalParagraph: 'For Canadian Team Members, individual sessions cost just $75/hour which may be reimbursable through your paramedical health benefit or paid privately. For US Team Members, individual sessions cost just $90/hour and may be paid out through your health insurer or paid privately.',
  },
  {
    marketingURL: 'bench',
    path: 'bench',
    title: 'Bench',
    logo: benchLogo,
    logoClass: 'corporate-nav-logo',
    full: true,
    email_domain: ['bench.co', 'inkblottherapy.com'],
    dependants: true,
    showFlags: true,
    minutes: '2 hours',
    subsequentSessions: 'For Canadian Team Members, individual sessions cost just $75/hour ($120/hour for couples counselling) which may be reimbursable through your paramedical health benefit or paid privately. For US Team Members, individual sessions cost just $90/hour ($144/hour for couples counselling) and may be paid out through your health insurer or paid privately.',
  },
  {
    marketingURL: 'accm',
    path: 'accm',
    title: 'ACCM',
    logo: accmLogo,
    logoClass: 'key-living-logo',
    minutes: '3 hours',
    subsequentSessions: 'Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) and may be reimbursable under your paramedical health benefit if you have or paid privately.',
    dependants: true,
    partial: true,
  },
  {
    marketingURL: 'brandheroes',
    path: 'brandheroes',
    title: 'Brand Heroes',
    logo: brandHeroesLogo,
    logoClass: 'key-living-logo',
    dependants: true,
    email_domain: ['inkblottherapy.com', 'brandheroes.ca'],
    full: true,
    minutes: '3 hours',
  },
  {
    marketingURL: 'flipp',
    path: 'flipp',
    title: 'Flipp',
    logo: flippLogo,
    logoClass: 'key-living-logo',
    dependants: true,
    email_domain: ['inkblottherapy.com', 'flipp.com'],
    full: true,
    minutes: '90 minutes',
    subsequentSessions: 'After that, you can continue with counselling and use your League Health Spending Account, Lifestyle Spending Account or health benefits coverage. Inkblot’s rates are $37.50 per half-hour, $75 per hour, and $112.50 for 90 minutes',
  },
  // EFAP Company template
  // {
  //   path: 'path', // This is the same as the customized_url in the database
  //   marketingURL: 'path', // Usually the same as the path but can be customized for companies with multiple portal pages
  //   title: 'Title', // Name of the company as shown on Landing page
  //   logo: companyLogo, // Logo imported from above
  //   logoClass: 'class-name', // Use 'corporate-nav-logo' for wider, rectangular logos and 'key-living-logo' for smaller, square or circular logos
  //   EFAP: true, // True if you want the text to read EFAP
  //   EAP: true, // True if you want the text to read EAP (also need EFAP: true)
  //   crisisText: true, // True for all new EAP contracts
  //   dependants: true, // True if company includes dependants
  //   subsequentSessions: 'Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be covered by your private health insurance, benefits coverage or paid privately.', // To be customized if the last paragraph is different than the default
  //   frenchPage: true, // True if English and French
  //   subsequentSessionsFr: 'Les séances individuelles subséquentes ne coûtent que 75 $ / heure (120 $ / heure pour les conseils aux couples) qui peuvent être remboursées par le biais de votre assurance maladie paramédicale ou payées en privé.', // Needs to be customized if frenchPage: true,
  //   noneSignup: true, // True if portal gating is None
  //   partial: true, // True if portal gating is Partial
  //   full: true, // True if portal gating is Full
  //   email_domain: ['inkblottherapy.com', 'url.com'], // For Full portal gating use Inkblot url for testing and company url based on eligibility list
  // },
  // Copy the template below to start
  // {
  //   path: 'path',
  //   marketingURL: 'path',
  //   title: 'Title',
  //   logo: companyLogo,
  //   logoClass: 'corporate-nav-logo',
  //   EFAP: true,
  //   crisisText: true,
  //   dependants: true,
  //   full: true,
  //   email_domain: ['inkblottherapy.com', 'url.com'],
  // },
  {
    path: 'astrazeneca',
    marketingURL: 'astrazeneca',
    title: 'AstraZeneca',
    logo: astraLogo,
    logoClass: 'key-living-logo',
    EFAP: true,
    EAP: true,
    crisisText: true,
    dependants: true,
    fullyGated: true,
    usOnly: true,
  },
  {
    path: 'browze',
    marketingURL: 'browze',
    title: 'Browze',
    logo: browzeLogo,
    logoClass: 'key-living-logo',
    EFAP: true,
    crisisText: true,
    dependants: true,
    fullyGated: true,
  },
  {
    path: 'nanoleaf',
    marketingURL: 'nanoleaf',
    title: 'Nanoleaf',
    logo: nanoleafLogo,
    logoClass: 'key-living-logo',
    EFAP: true,
    crisisText: true,
    dependants: true,
    fullyGated: true,
    frenchPage: true,
  },
  {
    path: 'mcco',
    marketingURL: 'mcco',
    title: 'MCCO',
    logo: mccoLogo,
    logoClass: 'corporate-nav-logo',
    EFAP: true,
    crisisText: true,
    dependants: true,
    full: true,
    email_domain: ['inkblottherapy.com', 'mcco.ca'],
  },
  {
    path: 'colligan',
    marketingURL: 'colligan',
    title: 'Colligan Law',
    logo: colliganLogo,
    logoClass: 'key-living-logo',
    EFAP: true,
    crisisText: true,
    dependants: true,
    full: true,
    email_domain: ['inkblottherapy.com', 'colliganlaw.com'],
  },
  {
    path: 'nbpl',
    marketingURL: 'nbpl',
    title: 'North Bay Public Library',
    logo: nbplLogo,
    logoClass: 'key-living-logo',
    EFAP: true,
    crisisText: true,
    dependants: true,
    partial: true,
  },
  {
    marketingURL: 'atb',
    path: 'atb',
    title: 'ATB Financial',
    logo: atbLogo,
    logoClass: 'prosper-logo',
    EFAP: true,
    efapThird: 'If you are in crisis, contact:  1-855-933-0103 (toll-free, 24/7).',
    dependants: true,
    email_domain: ['inkblottherapy.com', 'atb.com'],
    crisisText: true,
  },
  {
    marketingURL: 'aecon',
    path: 'aecon',
    title: 'Aecon',
    logo: aeconLogo,
    logoClass: 'corporate-nav-logo',
    EFAP: true,
    efapThird: 'If you are in crisis, contact:  1-855-933-0103 (toll-free, 24/7).',
    dependants: true,
    email_domain: ['inkblottherapy.com', 'aecon.com'],
    crisisText: true,
    full: true,
  },
  {
    marketingURL: 'kearney',
    path: 'kearney',
    title: 'Kearney',
    logo: kearneyLogo,
    logoClass: 'corporate-nav-logo',
    dependants: true,
    email_domain: ['inkblottherapy.com', 'kearney.com'],
    full: true,
    EFAP: true,
    EAP: true,
    efapSecond: 'Your initial individual counselling session is complimentary, and through the EAP, your next 12 hours of counselling per fiscal year are covered. After that, you can continue counselling with sessions reimbursable through your Health Spending Account (HSA). Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling).',
    crisisText: true,
  },
  {
    marketingURL: 'thg',
    path: 'thg',
    title: 'The Humphrey Group',
    logo: thgLogo,
    logoClass: 'key-living-logo',
    dependants: true,
    email_domain: ['inkblottherapy.com', 'thehumphreygroup.com'],
    full: true,
    EFAP: true,
    EAP: true,
    efapSecond: 'Your initial individual counselling session is complimentary, and through the EAP, your next 5 hours of counselling per fiscal year are covered. After that, you can continue counselling with sessions reimbursable through your paramedical health benefits. Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling).',
    crisisText: true,
  },
  {
    marketingURL: 'ni',
    path: 'thg',
    title: 'Niagara Institute',
    logo: niLogo,
    logoClass: 'key-living-logo',
    dependants: true,
    email_domain: ['inkblottherapy.com', 'niagarainstitute.com'],
    full: true,
    EFAP: true,
    EAP: true,
    efapSecond: 'Your initial individual counselling session is complimentary, and through the EAP, your next 5 hours of counselling per fiscal year are covered. After that, you can continue counselling with sessions reimbursable through your paramedical health benefits. Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling).',
    crisisText: true,
  },
  {
    marketingURL: 'humi',
    path: 'humi',
    title: 'Humi',
    logo: humiLogo,
    logoClass: 'key-living-logo',
    dependants: true,
    email_domain: ['inkblottherapy.com', 'humi.ca'],
    full: true,
    EFAP: true,
    EAP: true,
    efapSecond: 'Your initial individual counselling session is complimentary, and through the EAP, your next 5 hours of counselling per fiscal year are covered. After that, you can continue counselling with sessions reimbursable through your paramedical health benefits. Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling).',
    crisisText: true,
  },
  {
    marketingURL: 'koho',
    path: 'koho',
    title: 'KOHO',
    logo: kohoLogo,
    logoClass: 'key-living-logo',
    dependants: true,
    email_domain: ['inkblottherapy.com', 'koho.ca'],
    full: true,
    EFAP: true,
    EAP: true,
    efapSecond: 'Your initial counselling session is complimentary, and through the EAP, your next 5 hours of counselling per year are covered. After that, you can continue counselling with sessions reimbursable through your Health Spending Account (HSA). Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling).',
    crisisText: true,
  },
  {
    marketingURL: 'forthspace',
    path: 'forthspace',
    title: 'Forthspace',
    logo: forthspaceLogo,
    logoClass: 'key-living-logo',
    dependants: true,
    EFAP: true,
    EAP: true,
    partial: true,
    crisisText: true,
    efapSecond: 'Your initial individual counselling session is complimentary, and through the EAP, your next 5 hours of counselling per fiscal year are covered. After that, you can continue counselling with sessions reimbursable through your Health Spending Account (HSA). Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling).',
  },
  {
    marketingURL: 'dsb1',
    path: 'dsb1',
    title: 'District School Board Ontario North East',
    logo: dsb1Logo,
    logoClass: 'key-living-logo',
    full: true,
    email_domain: ['dsb1.ca', 'inkblottherapy.com'],
    dependants: true,
    EFAP: true,
  },
  {
    marketingURL: 'brightandearly',
    path: 'brightandearly',
    title: 'Bright + Early',
    logo: brightandearlyLogo,
    logoClass: 'key-living-logo',
    dependants: true,
    partial: true,
    EFAP: true,
    crisisText: true,
  },
  {
    marketingURL: 'pelmorex',
    path: 'pelmorex',
    title: 'Pelmorex',
    logo: pelmorexLogo,
    logoClass: 'corporate-nav-logo',
    EFAP: true,
    frenchPage: true,
    efapSecond: 'Your initial individual counselling session is complimentary, and through the EFAP, your next 5 hours of counselling per fiscal year are covered. After that, you can continue counselling with sessions reimbursable through your paramedical health coverage and/or HSA. Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling).',
    efapSecondFr: "Votre première séance de counseling individuelle est gratuite et, grâce au PAEF, vos 5 prochaines heures de counseling par exercice sont couvertes. Après cela, vous pouvez continuer le conseil avec des séances remboursables via votre assurance maladie paramédicale et / ou HSA. Les tarifs d'Inkblot sont de 75 $ / heure de counseling individuel et 120 $ / heure de counselling couple / famille.",
    crisisText: true,
    dependants: true,
  },
  {
    marketingURL: 'prosper',
    path: 'prosper',
    title: 'Prosper',
    logo: prosperLogo,
    logoClass: 'prosper-logo',
    EFAP: true,
  },
  {
    marketingURL: 'techstars',
    path: 'techstars',
    title: 'Techstars',
    logo: techstarsLogo,
    logoClass: 'key-living-logo',
    EFAP: true,
  },
  {
    marketingURL: 'sunwing',
    path: 'sunwing',
    title: 'Sunwing',
    logo: sunwingLogo,
    logoClass: 'corporate-nav-logo',
    dependants: true,
    frenchPage: true,
    efapSecond: 'Subsequent individual sessions cost just $75/hour ($120/hour for couples counselling) which may be reimbursable through your paramedical health benefit or paid privately.',
    efapSecondFr: 'Les séances individuelles subséquentes ne coûtent que 75 $ / heure (120 $ / heure pour les conseils aux couples) qui peuvent être remboursées par le biais de votre assurance maladie paramédicale, compte de dépenses de santé ou payées en privé.',
    EFAP: true,
    crisisText: true,
    EAP: true,
    noneSignup: true,
  },
  {
    marketingURL: 'moseyandmosey',
    path: 'moseyandmosey',
    title: 'Mosey & Mosey',
    logo: moseyLogo,
    logoClass: 'key-living-logo',
    EFAP: true,
    crisisText: true,
    dependants: true,
    full: true,
    email_domain: ['mosey.on.ca', 'inkblottherapy.com'],
    EAP: true,
  },
  {
    marketingURL: 'intelex',
    path: 'intelex',
    title: 'Intelex',
    logo: intelexLogo,
    logoClass: 'corporate-nav-logo',
    full: true,
    email_domain: ['inkblottherapy.com', 'intelex.com'],
    efapThird: 'Intelex will cover 3 hours of counselling per employee each year. Subsequent sessions can be reimbursed through your insured benefits, League Health Spending Account, then Wellness Spending Allowance, depending on your coverage in the US or Canada. Inkblot’s individual counselling rates are $37.50 per 30 minutes in Canada, and $45.00 per 30 minutes in the US.',
    showFlags: true,
    EFAP: true,
    EAP: true,
    crisisText: true,
    dependants: true,
    usNotFree: true,
  },
  {
    marketingURL: 'motorefi',
    path: 'motorefi',
    title: 'MotoRefi',
    logo: motorefiLogo,
    logoClass: 'corporate-nav-logo',
    EFAP: true,
    dependants: true,
    full: true,
    email_domain: ['inkblottherapy.com', 'motorefi.com'],
    removeLegalFinancial: true,
  },
  {
    marketingURL: 'minesense',
    path: 'minesense',
    title: 'MineSense',
    logo: minesenseLogo,
    logoClass: 'key-living-logo',
    dependants: true,
    email_domain: ['inkblottherapy.com', 'minesense.com'],
    EFAP: true,
    full: true,
    crisisText: true,
  },
  {
    marketingURL: 'guaranteedremovals',
    path: 'guaranteedremovals',
    title: 'Guaranteed Removals',
    logo: guaranteedLogo,
    logoClass: 'key-living-logo',
    EFAP: true,
    partial: true,
  },
  {
    marketingURL: 'willful',
    path: 'willful',
    title: 'Willful',
    logo: willfulLogo,
    logoClass: 'corporate-nav-logo',
    dependants: true,
    email_domain: ['inkblottherapy.com', 'willful.co'],
    full: true,
  },
];

export default companies;
