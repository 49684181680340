/* eslint-disable react/no-did-mount-set-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Bowser from 'bowser';

import inkblotLogoLight from '../../images/_inkblot-logo-light.png';
import inkblotLogoDark from '../../images/nav/inkblot-logo.svg';
import inkblotLogoMobile from '../../images/nav/inkblot-logo-mobile.svg';
import SignUp from './SignUp';
import SignIn from '../auth/SignIn';
import HamburgerBlack from '../../images/_hamburger.svg';
import HamburgerWhite from '../../images/_hamburger-white.svg';
import close from '../../images/x.svg';
import { unauthAuditProgress } from '../../utils/pageUtils';
import { logEvent, OPEN_SIGNUP, OPEN_SIGNIN } from '../../utils/amplitude';

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigationShow: false,
      signUpOpen: false,
      signInOpen: false,
      isScrollDown: this.getScrollTop() > 60,
    };
  }

  componentWillMount() {
    this.closeAuths();
  }

  componentDidMount() {
    window.addEventListener('scroll', () => {
      this.setState({ isScrollDown: this.getScrollTop() > 60 });
    });
    const signUp = this.props.location.search === '?signup=true';
    const signIn = this.props.location.search === '?signin=true';

    if (signUp) {
      localStorage.setItem('signUp', 'open');
      this.setState({ signUpOpen: true });
    }
    if (signIn) {
      localStorage.setItem('signIn', 'open');
      this.setState({ signInOpen: true });
    }

    localStorage.getItem('signIn');
    localStorage.getItem('signUp');
  }

  componentDidUpdate(_prevProps, prevState) {
    const { signUpOpen, signInOpen } = this.state;

    if (signUpOpen && signUpOpen !== prevState.signUpOpen) {
      logEvent(OPEN_SIGNUP, { page: '' });
      unauthAuditProgress('', 'open_signup');
    }

    if (signInOpen && signInOpen !== prevState.signInOpen) {
      logEvent(OPEN_SIGNIN, { page: '' });
      unauthAuditProgress('', 'open_signin');
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.getScrollTop, false);
  }

  setLanguage = lang => {
    localStorage.setItem('selectedLanguage', lang);
    window.location.reload();
  };

  getScrollTop = () =>
    (window.pageYOffset !== undefined
      ? window.pageYOffset
      : (document.documentElement || document.body.parentNode || document.body)
        .scrollTop);

  toggleElements = element => {
    this.setState(prevState => ({ [element]: !prevState[element] }));
    this.pushOver(element);
  };

  pushOver = element => {
    const browser = Bowser.getParser(window.navigator.userAgent);

    if (browser.getBrowser().name !== 'Safari') {
      if (element === 'signIn') {
        const amount = window.innerWidth < 400 ? window.innerWidth : 400;
        document.getElementById('___gatsby').style.right = `${amount}px`;
      } else if (element === 'signUp') {
        const amount = window.innerWidth < 600 ? window.innerWidth : 600;
        document.getElementById('___gatsby').style.right = `${amount}px`;
      }
    }
  };

  toggleElements = element => {
    this.setState(prevState => ({ [element]: !prevState[element] }));
    this.pushOver(element);
  };

  toggleSignInDrawer = () => {
    this.setState({
      navigationShow: false,
      signInOpen: true,
      signUpOpen: false,
    });
    localStorage.setItem('signIn', 'open');
    localStorage.setItem('signUp', 'close');
    this.pushOver('signIn');
    this.forceUpdate();
  };

  toggleSignUpDrawer = () => {
    this.setState({
      navigationShow: false,
      signInOpen: false,
      signUpOpen: true,
    });
    localStorage.setItem('signIn', 'close');
    localStorage.setItem('signUp', 'open');
    this.pushOver('signUp');
    this.forceUpdate();
  };

  closeAuths = () => {
    this.setState({
      signInOpen: false,
      signUpOpen: false,
    });
    localStorage.setItem('signIn', 'close');
    localStorage.setItem('signUp', 'close');
    document.getElementById('___gatsby').style.right = '0px';
    this.forceUpdate();
  };

  renderLinks = () => (
    <div className="nav-item-container">
      <div className="auth-buttons">
        <div>
          <span
            className="nav-item"
            onClick={() => {
              this.setState({ navigationShow: false, signInOpen: true });
              localStorage.setItem('signUp', 'close');
            }}
            role="presentation"
          >
            <FormattedMessage id="navigation.signIn" defaultMessage="Sign In" />
          </span>
        </div>

        <button
          className="medium-blue-button"
          onClick={() => {
            this.setState({ navigationShow: false, signInOpen: false });
            localStorage.setItem('signUp', 'open');
          }}
        >
          <FormattedMessage id="navigation.createMyAccount" defaultMessage="Get Started" />
        </button>
      </div>
    </div>
  );

  render() {
    const {
      navigationShow, isScrollDown, signInOpen, signUpOpen,
    } = this.state;
    const { location } = this.props;

    let navTheme = '';

    let hamburger = HamburgerBlack;
    let inkblotLogo = inkblotLogoDark;
    if (
      (location.pathname === '/avail') &&
      !isScrollDown
    ) {
      navTheme = 'navigation-bar-blue';
      inkblotLogo = inkblotLogoLight;
      hamburger = HamburgerWhite;
    }

    const signIn = localStorage.getItem('signIn') === 'open' || signInOpen;
    const signUp = localStorage.getItem('signUp') === 'open' || signUpOpen;
    return (
      <div>
        <div className={`navigation-bar-container ${navTheme}`} style={{ marginTop: 0 }}>
          <Link to="/" style={{ display: 'flex' }}>
            <img className="ink-logo" title="Inkblot" src={inkblotLogo} alt="Logo" />
          </Link>
          <div className="email-support">
            <FormattedHTMLMessage
              tagName="span"
              id="navigation.emailSupport"
              defaultMessage="Have a question? Send us an email to <a href='mailto:support@inkblottherapy.com'>support@inkblottherapy.com</a>"
            />
          </div>
          <div className="league-bar-right">
            <div className="auth-buttons">
              <div>
                <span
                  className="nav-item"
                  onClick={() => {
                this.setState({ navigationShow: false, signInOpen: true });
                localStorage.setItem('signUp', 'close');
              }}
                  role="presentation"
                >
                  <FormattedMessage id="navigation.signIn" defaultMessage="Sign In" />
                </span>
              </div>
              <button
                className="medium-blue-button"
                onClick={() => {
                  this.setState({ navigationShow: false, signInOpen: false });
                  localStorage.setItem('signUp', 'open');
                }}
              >
                <FormattedMessage id="navigation.createMyAccount" defaultMessage="Get Started" />
              </button>
            </div>
          </div>
          <div className="navigation-bar-right-mobile">
            <div role="presentation" onClick={() => this.toggleElements('navigationShow')}>
              <img style={{ marginTop: '5px', cursor: 'pointer' }} src={hamburger} alt="hamburger" />
            </div>
          </div>
          <div
            style={navigationShow ? { height: '100%' } : { height: '0%' }}
            className="navigation-overlay-mobile"
          >
            <div role="presentation" onClick={() => this.toggleElements('navigationShow')}>
              <img className="navigation-mobile-close" src={close} alt="" />
            </div>
            <div className="navigation-overlay-mobile-content">
              <Link to="/">
                <img className="ink-logo" title="Inkblot" src={inkblotLogoMobile} alt="Logo" />
              </Link>
              {this.renderLinks()}
            </div>
          </div>
          <SignIn
            signInOpen={signIn}
            toggleSignInDrawer={() => this.closeAuths()}
            toggleSignUpDrawer={this.toggleSignUpDrawer}
          />
          <SignUp
            signUpOpen={signUp === true}
            toggleSignInDrawer={this.toggleSignInDrawer}
            toggleSignUpDrawer={() => this.closeAuths()}
          />
        </div>
      </div>
    );
  }
}

Navigation.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Navigation;
