import React from 'react';
import { Link } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import FacebookLogo from '../images/footer/facebook-button.svg';
import InstagramLogo from '../images/footer/instagram-button.svg';
import LinkedInLogo from '../images/footer/linkedin-button.svg';
import TwitterLogo from '../images/footer/twitter-button.svg';
import emailLogo from '../images/footer/email-button.svg';
import { whatWeTreat, aboutUs, legal, ourServices, learning } from './footerData';

const Footer = () => (
  <div className="footer-wrapper">
    {/* <div className="footer-image">
      <img src={teamwork5} alt="Our door is always open." className="teamwork-photo" />
    </div> */}
    <div className="footer-header">
      <div>
        <h1><FormattedMessage id="footer.openDoor" defaultMessage="Our door is always open." /></h1>
        <h1><FormattedMessage id="footer.contactAnytime" defaultMessage="Contact us anytime." /></h1>
      </div>
      <div>
        <button>
          <div>
            <img src={emailLogo} alt="email logo" />
            <a href="mailto:support@inkblottherapy.com">
              <span >support@inkblottherapy.com</span>
            </a>
          </div>
        </button>
      </div>
    </div>
    <div className="footer-container">
      <div className="footer-row">
        <div className="treatment-container">
          <h4><FormattedMessage id="footer.whatWeTreat" defaultMessage="What We Treat" /></h4>
          <div className="what-we-treat">
            <ul>
              {
                whatWeTreat.slice(0, 7).map((treat) => (
                  <li key={treat.id}>
                    {/* <Link title={treat.name} to={treat.link}> */}
                    <FormattedMessage id={`footer.${treat.id}`} defaultMessage={treat.name} />
                    {/* </Link> */}
                  </li>
                ))
              }
            </ul>
            <ul>
              {
                whatWeTreat.slice(7).map((treat) => (
                  <li key={treat.id}>
                    {/* <Link title={treat.name} to={treat.link}> */}
                    <FormattedMessage id={`footer.${treat.id}`} defaultMessage={treat.name} />
                    {/* </Link> */}
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
      </div>
      <div className="about-services">
        <div>
          <h4><FormattedMessage id="footer.aboutUs" defaultMessage="About Us" /></h4>
          <ul>
            {
              aboutUs.map((about) => (
                <li key={about.id}>
                  <Link title={about.name} to={about.link}>
                    <FormattedMessage id={`footer.${about.id}`} defaultMessage={about.name} />
                  </Link>
                </li>
              ))
            }
          </ul>
        </div>
        {/* <div>
          <h4><FormattedMessage id="footer.ourServices" defaultMessage="Our Services" /></h4>
          <ul className="ul-services">
            {
              ourServices.map((service) => (
                <li key={service.id}>
                  <Link title={service.name} to={service.link}>
                    <FormattedMessage id={`footer.${service.id}`} defaultMessage={service.name} />
                  </Link>
                </li>
              ))
            }
          </ul>
        </div> */}
      </div>
      <div className="footer-column">
        <div className="footer-row">
          <div>
            <h4 className="h4-last"><FormattedMessage id="footer.legal" defaultMessage="Legal" /></h4>
            <ul>
              {
                legal.map((legalItem) => (
                  <li key={legalItem.id}>
                    <Link title={legalItem.name} to={legalItem.link}>
                      <FormattedMessage id={`footer.${legalItem.id}`} defaultMessage={legalItem.name} />
                    </Link>
                  </li>
                ))
              }
            </ul>
          </div>
          <div>
            <h4><FormattedMessage id="footer.learning" defaultMessage="Learning" /></h4>
            <ul>
              {
              learning.map((learn) => (
                <li key={learn.id}>
                  <Link title={learn.name} to={learn.link}>
                    <FormattedMessage id={`footer.${learn.id}`} defaultMessage={learn.name} />
                  </Link>
                </li>
              ))
            }
            </ul>
          </div>
        </div>
        <div className="footer-row">
          <div className="fc-social-link">
            <h2><FormattedMessage id="footer.letsGetSocial" defaultMessage="Let's get social." /></h2>
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/inkblotpsychotherapy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={FacebookLogo} alt="Facebook" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/inkblottherapy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={InstagramLogo} alt="Instagram" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/inkblottherapy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={LinkedInLogo} alt="LinkedIn" />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/inkblottherapy?lang=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={TwitterLogo} alt="Twitter" />
                </a>
              </li>
            </ul>
          </div>
          <div className="copyright">© {new Date().getFullYear()} INKBLOT TECHNOLOGIES INC.</div>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
