import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { navigate } from 'gatsby';

import {
  SERVER_URL,
  THERAPY_URL,
  MARKETING_URL,
  OTHER_SERVER_URL,
  OTHER_THERAPY_URL,
} from '../../utils/environment';
import SpinnerButton from '../SpinnerButton';
import X from '../../images/dark-x.svg';
import { COMPLETED_SIGNIN, logEvent, setUserId } from '../../utils/amplitude';

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.clearError, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.clearError, false);
  }

  clearError = () => {
    this.setState({ error: null });
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.signIn();
  };

  redirect = (response, url, email) => {
    const twoFactorId = response.data.two_factor_id;
    const userId = response.data.user_id;

    setUserId(userId);
    logEvent(COMPLETED_SIGNIN);

    if (twoFactorId) {
      navigate('/two_factor', { state: { email, twoFactorId } });
    } else {
      const token = response.data.access_token;
      const inkblotUrl = `${url}/redirect/${token}`;

      window.location.replace(inkblotUrl);
      this.setState({ loading: false });
    }
  };

  // copied from src/components/custom/SignIn.js
  signIn = () => {
    this.setState({ loading: true, error: null });
    const values = _.omit(this.state, ['error']);

    axios
      .post(`${SERVER_URL}/api/login`, { ...values, source: 'therapy' })
      .then(mainResponse =>
        // success in DB
        this.redirect(mainResponse, THERAPY_URL, values.email),
      )
      .catch(mainError => {
        if (mainError.response.status === 422) {
          // no exist in DB, check other country DB
          axios
            .get(
              `${OTHER_SERVER_URL}/api/signup/check_email?email=${encodeURIComponent(
                values.email,
              )}`,
            )
            .then(() => {
              // not exist
              this.setState({ loading: false });
              this.setState({ error: mainError.response.data.error });
            })
            .catch(otherCheckError => {
              if (otherCheckError.response.status === 422) {
                // exist in other country DB
                axios
                  .post(`${OTHER_SERVER_URL}/api/login`, {
                    ...values,
                    source: 'therapy',
                  })
                  .then(otherResponse =>
                    // success in other country DB
                    this.redirect(
                      otherResponse,
                      OTHER_THERAPY_URL,
                      values.email,
                    ),
                  )
                  .catch(otherError => {
                    // exist in other country DB, wrong password
                    this.setState({ loading: false });
                    this.setState({ error: otherError.response.data.error });
                  });
              } else {
                // error in checking other country DB
                this.setState({ loading: false });
                this.setState({ error: mainError.response.data.error });
              }
            });
        } else {
          // exist in DB, wrong password
          this.setState({ loading: false });
          this.setState({ error: mainError.response.data.error });
        }
      });
  };

  switchDrawers = () => {
    this.props.toggleSignInDrawer();
    this.props.toggleSignUpDrawer();
  };

  render() {
    const { signInOpen } = this.props;
    const { error, loading } = this.state;
    const language = localStorage.getItem('selectedLanguage');
    const hasScrollbar =
      window.innerWidth > document.documentElement.clientWidth;
    // eslint-disable-next-line
    const authStyle = signInOpen
      ? hasScrollbar
        ? { right: '-15px' }
        : { right: '0px' }
      : hasScrollbar
      ? { right: '-415px', boxShadow: 'none' }
      : { right: '-400px', boxShadow: 'none' };

    return (
      <div>
        <div className="auth" style={authStyle}>
          <div className="sign-in">
            <p className="title">
              <FormattedMessage id="auth.signIn" defaultMessage="Sign In" />
            </p>
            <p className="subtitle">
              <FormattedMessage
                id="auth.dontHaveAnAccount"
                defaultMessage="Don&#39;t have an account?&nbsp;"
              />
              <span
                className="subtitle-link"
                onClick={() => this.switchDrawers()}
                role="presentation"
              >
                <FormattedMessage id="auth.signUp" defaultMessage="Sign Up" />
              </span>
            </p>
            <form
              className="form-container sign-in-form"
              onSubmit={this.handleSubmit}
            >
              <div className="input-container">
                <p className="label">
                  <FormattedMessage id="auth.email" defaultMessage="Email" />
                </p>
                <input
                  onChange={value => this.handleChange(value)}
                  type="text"
                  name="email"
                  placeholder={
                    language === 'fr'
                      ? 'Votre adresse de courriel'
                      : 'Your Email'
                  }
                />
              </div>
              <div className="input-container">
                <p className="label">
                  <FormattedMessage id="auth.password" defaultMessage="Email" />
                </p>
                <input
                  onChange={value => this.handleChange(value)}
                  type="password"
                  name="password"
                  placeholder={
                    language === 'fr' ? 'Votre mot de passe' : 'Your Password'
                  }
                />
                <p className={`error-text ${error ? '' : 'hidden'}`}>{error}</p>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <SpinnerButton
                  label={
                    <FormattedMessage
                      id="auth.signIn"
                      defaultMessage="Sign In"
                    />
                  }
                  type="submit"
                  loading={loading}
                />
              </div>
            </form>
            <div className="asi-options">
              <p className="asio-forget-pw">
                <a href={`${MARKETING_URL}/forgot_password`}>
                  <span className="asi-link">
                    <FormattedMessage
                      id="auth.forgotMyPassword"
                      defaultMessage="Forgot my password"
                    />
                  </span>
                </a>
              </p>
              <p className="asio-counsellor">
                <FormattedMessage
                  id="auth.ifYouAreACounsellor"
                  values={{
                    pleaseSignEN: (
                      <a href="https://app.inkblotpractice.com/signin">
                        <b className="asi-link">please sign in here</b>
                      </a>
                    ),
                    pleaseSignFR: (
                      <a href="https://app.inkblotpractice.com/signin">
                        <b className="asi-link">veuillez vous connecter ici</b>
                      </a>
                    ),
                  }}
                />
              </p>
            </div>
            <div
              className="close"
              onClick={this.props.toggleSignInDrawer}
              role="presentation"
            >
              <img src={X} alt="close" />
            </div>
          </div>
        </div>
        <div
          className="overlay"
          onClick={this.props.toggleSignInDrawer}
          role="presentation"
          style={
            signInOpen
              ? { visibility: 'visible', opacity: '1', right: '400px' }
              : { visibility: 'hidden', opacity: '0', right: 0 }
          }
        />
      </div>
    );
  }
}

SignIn.propTypes = {
  signInOpen: PropTypes.bool.isRequired,
  toggleSignInDrawer: PropTypes.func.isRequired,
  toggleSignUpDrawer: PropTypes.func.isRequired,
};

export default SignIn;
