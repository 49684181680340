import React, { Component } from 'react';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import _ from 'lodash';
import queryString from 'query-string';

import { signUpData } from '../../pagePartials/auth';
import SpinnerButton from '../SpinnerButton';
import X from '../../images/dark-x.svg';
import UnitedStates from '../../images/united-states.png';
import Canada from '../../images/canada.png';
import { SERVER_URL, THERAPY_URL, COUNTRY, OTHER_MARKETING_URL } from '../../utils/environment';
import { auditProgress } from '../../utils/pageUtils';
import { logEvent, COMPLETED_SIGNUP } from '../../utils/amplitude';

class SignUp extends Component {
  constructor(props) {
    super(props);

    const params = queryString.parse(window.location.search);

    this.state = {
      signUpDisabled: true,
      signUpError: null,
      error: {},
      recentlyUpdated: null,
      loading: false,
      errCountry: null,
      partnerInviteToken: params.partner_token || null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.recentlyUpdated !== prevState.recentlyUpdated) {
      this.validateForm();
    }
  }

  emailExists = email => {
    axios
      .get(`${SERVER_URL}/api/signup/check_email?email=${encodeURIComponent(email)}`)
      .then(() => {})
      .catch(e => {
        this.setState({
          error: {
            ...this.state.error,
            email: e.response.data.error,
          },
        });
      });
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: name === 'dob' ? this.normalizeDate(value) : value,
      recentlyUpdated: name,
    });
  };

  handleChangeRadio = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.signUp();
  };

  signUp = () => {
    const { referralCode } = this.props;
    const { partnerInviteToken } = this.state;

    const values = _.omit(this.state, [
      'signUpDisabled',
      'error',
      'signUpError',
      'recentlyUpdated',
      'other_referral_source',
      'errCountry',
      'partnerInviteToken',
    ]);

    if (partnerInviteToken) {
      values.partner_invitation_token = partnerInviteToken;
      values.referral_source = 'Partner Invitation';
    }

    if (referralCode) {
      values.referral_source = referralCode;
    }
    if (values.referral_source === 'Other') {
      values.referral_source = this.state.other_referral_source;
    }

    let mergedValues = { ...values, source: 'therapy' };
    if (window.location.pathname.split('/')[1] === 'avail') {
      mergedValues = { ...values, source: 'therapy', school: 'avail' };
    }

    this.setState({ loading: true });
    axios
      .post(`${SERVER_URL}/api/signup`, mergedValues)
      .then(response => {
        const token = response.data.access_token;
        logEvent(COMPLETED_SIGNUP, { page: referralCode || '' });
        auditProgress(referralCode || '', 'completed_signup', token);
        window.location.replace(`${THERAPY_URL}/redirect/${token}`);
        this.setState({ loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
        this.setState({ signUpError: e.response.data.error });
      });
  };
  switchDrawers = () => {
    this.props.toggleSignUpDrawer();
    this.props.toggleSignInDrawer();
  };

  validateForm = () => {
    const isFr = localStorage.getItem('selectedLanguage') === 'fr';
    const requiredField = isFr
      ? 'Ceci est un champ obligatoire'
      : 'This is a required field';
    const emailValid = isFr
      ? "L'email doit être valide"
      : 'Email must be valid';
    const passwordSix = isFr
      ? 'Le mot de passe doit être au moins de 6 caractères '
      : 'Password must be at least 6 characters';
    const passwordMatch = isFr
      ? 'Les mots de passe doivent correspondre'
      : 'Passwords must match';
    const useUSA = isFr ? 'Veuillez utiliser le site Web des États-Unis.' : 'Click here to use the US website.';
    const useCanada = isFr ? 'Veuillez utiliser le site Web canadien.' : 'Click here to use the Canadian website.';

    const { referralCode } = this.props;
    const {
      first_name,
      last_name,
      email,
      password,
      password_confirm,
      referral_source,
      other_referral_source,
      country,
      partnerInviteToken,
    } = this.state;
    const error = {};

    if (first_name === '') {
      error.first_name = requiredField;
    }
    if (last_name === '') {
      error.last_name = requiredField;
    }
    if (email === '') {
      error.email = requiredField;
    }
    if (password === '') {
      error.password = requiredField;
    }
    if (!referralCode && !partnerInviteToken && referral_source === '') {
      error.referral_source = requiredField;
    }

    if (!partnerInviteToken) {
      // validate Country select
      if (country && country !== COUNTRY) {
        error.country = COUNTRY === 'CA' ? useUSA : useCanada;
        this.setState({ errCountry: COUNTRY });
      } else {
        this.setState({ errCountry: null });
      }
    }

    if (email) {
      const validEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!validEmail.test(email)) {
        error.email = emailValid;
      }
      this.emailExists(email);
    }
    if (password) {
      if (password.length < 6) {
        error.password = passwordSix;
      } else if (password !== password_confirm) {
        error.password_confirm = passwordMatch;
      }
    }

    this.setState({
      recentlyUpdated: null,
      signUpDisabled:
        !_.isEmpty(error) ||
        !first_name ||
        !last_name ||
        !email ||
        !password ||
        !password_confirm ||
        (!partnerInviteToken && !country) ||
        (!referralCode && !partnerInviteToken &&
          (!referral_source ||
            (referral_source === 'Other' && !other_referral_source))),
      error,
    });
  };

  renderInputs = inputs => {
    const { error } = this.state;
    return (
      <div className="input-row">
        {_.map(inputs, ({
          id, name, defaultText, type, text, fullWidth,
          }) => (
            <div key={id} className={fullWidth ? 'full-width-input input-container' : 'input-container'}>
              <p className="label">
                <FormattedMessage id={text} />
              </p>
              <input
                id={name}
                onChange={event => this.handleChange(event)}
                type={type}
                name={name}
                placeholder={defaultText}
                value={this.state[name]}
              />
              <p className={`error-text ${error[name] ? '' : 'hidden'}`}>
                {error[name]}
              </p>
            </div>
        ))}
      </div>
    );
  };

  renderCountrySelect = () => {
    const {
      error,
      errCountry,
    } = this.state;

    const countrySelection = error.country;

    return (
      <div className="input-container">
        <p className="label">
          <FormattedMessage id="auth.countrySelection" />
        </p>
        <select
          className="referral-dropdown"
          onChange={value => this.handleChange(value)}
          type="text"
          name="country"
          defaultValue=""
        >
          <option value="">Please select an option in this list.</option>
          <option value="CA">Canada</option>
          <option value="US">United States</option>
        </select>
        <p className={`error-text ${countrySelection ? '' : 'hidden'}`} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {errCountry && (
            <a href={`${OTHER_MARKETING_URL}/?country_selected=true&signup=true`} >
              {countrySelection}
              <img src={COUNTRY === 'CA' ? UnitedStates : Canada} alt="flag" style={{ width: 20, marginLeft: 7 }} />
            </a>
          )}
        </p>
      </div>
    );
  }

  renderReferralSelect = () => {
    const {
      referral_source,
      other_referral_source,
      error,
    } = this.state;

    const isFr = localStorage.getItem('selectedLanguage') === 'fr';

    const requiredField = isFr ? 'Ceci est un champ obligatoire' : 'This is a required field';

    const referralSourceUndefined =
      error.referral_source ||
      (referral_source === 'Other' && !other_referral_source);

    return (
      <div className="input-container">
        <p className="label">
          <FormattedMessage id="auth.howDidYouHear" />
        </p>
        <select
          className="referral-dropdown"
          required
          onChange={value => this.handleChange(value)}
          type="text"
          name="referral_source"
          defaultValue=""
        >
          <option value="">
            Please select an option in this list.
          </option>
          <option value="Healthcare provider">
            Healthcare provider
          </option>
          <option value="Family/Friend">Family/Friend</option>
          <option value="Social Media">Social Media</option>
          <option value="Internet Search">Internet Search</option>
          <option value="Advertisement">Advertisement</option>
          <option value="Television/Radio">Television/Radio</option>
          <option value="YouTube/Podcast">YouTube/Podcast</option>
          <option value="Article">Article</option>
          <option value="Other">Other</option>
        </select>
        {referral_source === 'Other' && (
          <input
            style={{
              marginTop: '10px',
              color: '#212121',
              fontSize: '16px',
            }}
            onChange={value => this.handleChange(value)}
            type="text"
            name="other_referral_source"
            value={this.state[other_referral_source]}
          />
        )}
        <p
          className={`error-text ${
            referralSourceUndefined ? '' : 'hidden'
          }`}
        >
          {requiredField}
        </p>
      </div>
    );
  }

  render() {
    const { signUpOpen, referralCode } = this.props;
    const {
      signUpError,
      signUpDisabled,
      loading,
      partnerInviteToken,
    } = this.state;

    const hasScrollbar =
      window.innerWidth > document.documentElement.clientWidth;

    // eslint-disable-next-line
    const authStyle = signUpOpen
      ? hasScrollbar
        ? { right: '-15px' }
        : { right: '0px' }
      : hasScrollbar
        ? { right: '-615px', boxShadow: 'none' }
        : { right: '-600px', boxShadow: 'none' };

    return (
      <div>
        <div className="auth" style={authStyle}>
          <div className="sign-up">
            <h1 className="title">
              <FormattedMessage id="auth.signUp" defaultMessage="Sign Up" />
            </h1>
            <h2 className="subtitle">
              <FormattedMessage
                id="auth.alreadyHaveAnAccount"
                defaultMessage="Already have an account?&nbsp;"
              />
              <span
                className="subtitle-link"
                onClick={() => this.switchDrawers()}
                role="presentation"
              >
                <FormattedMessage id="auth.signIn" defaultMessage="Sign In" />
              </span>
            </h2>
            <form
              className="form-container sign-up-form"
              onSubmit={this.handleSubmit}
            >
              {this.renderInputs(signUpData.slice(0, 2))}
              {this.renderInputs(signUpData.slice(3, 4))}
              {this.renderInputs(signUpData.slice(4, 6))}
              {!partnerInviteToken && this.renderCountrySelect()}
              {!referralCode && !partnerInviteToken && this.renderReferralSelect()}
              <div>
                <p className={`error-text ${signUpError ? '' : 'hidden'}`}>
                  {signUpError}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <SpinnerButton
                  label={<FormattedMessage
                    id="auth.createMyAccount"
                    defaultMessage="CREATE MY ACCOUNT"
                  />}
                  type="submit"
                  loading={loading}
                  disabled={signUpDisabled}
                  style={{ width: '240px' }}
                />
              </div>
            </form>
            <div
              className="close"
              onClick={this.props.toggleSignUpDrawer}
              role="presentation"
            >
              <img src={X} alt="close" />
            </div>
          </div>
        </div>
        <div
          className="overlay"
          onClick={this.props.toggleSignUpDrawer}
          role="presentation"
          style={
            signUpOpen
              ? { visibility: 'visible', opacity: '1', right: '600px' }
              : { visibility: 'hidden', opacity: '0', right: 0 }
          }
        />
      </div>
    );
  }
}

SignUp.propTypes = {
  referralCode: PropTypes.string,
  signUpOpen: PropTypes.bool.isRequired,
  toggleSignUpDrawer: PropTypes.func.isRequired,
  toggleSignInDrawer: PropTypes.func.isRequired,
};

SignUp.defaultProps = {
  referralCode: '',
};

export default SignUp;
