const seoData = {
  default: {
    title: "Inkblot: Canada's top online video counselling app.",
    description:
      'We match you with certified therapists and coaches based on your needs and preferences.  Counselling takes place from the comfort of home or a quiet space at work at half the rate of the current average national fee for in-person counselling.',
    url: 'http://inkblottherapy.com',
  },
  faq: {
    title: 'Inkblot | Faq',
  },
  about: {
    title: 'Inkblot | About',
  },
  invest: {
    title: 'Inkblot | Investors',
    description: 'Information for investors',
  },
  business: {
    title: 'Revolutionizing Workplace Mental Health',
    description:
      'Inkblot has an adaptable employee mental wellness package that addresses mental health issues in the workplace and increases employee engagement and productivity.',
  },
  privacy: {
    title: 'Inkblot | Privacy',
    description: 'Privacy Policy',
  },
  conditions: {
    title: 'Inkblot | Terms and conditions',
    description: 'Terms & Conditions',
  },
};

export default seoData;
