import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'gatsby';
import CloseSvg from '../images/close-banner.svg';

const TEMP_BANNER_KEY = 'temp_banner_close';

class TempBanner extends Component {
  constructor(props) {
    super(props);

    const lastTime = localStorage.getItem(TEMP_BANNER_KEY);
    // 6 hours
    if (lastTime && (Date.now() - lastTime) < 1000 * 60 * 60 * 6) {
      props.setHeight(0);
    }
  }

  closeBanner = () => {
    localStorage.setItem(TEMP_BANNER_KEY, Date.now());
    this.props.setHeight(0);
  };

  render() {
    const { height } = this.props;

    return (
      <div className="temp-top-banner" style={{ height }}>
        <div className="ttb-contents">
          <p>
            We’re here to help.{' '}
            <span className="ttb-new-line"><br /></span>
            <Link to="/your-mental-health-and-coping-during-covid-19" >
              Read about managing anxiety during COVID-19
            </Link>
            .
          </p>
        </div>
        <div
          className="ttb-close-button"
          onClick={() => this.closeBanner()}
          onKeyDown={() => this.closeBanner()}
          role="button"
          tabIndex="0"
        >
          <img src={CloseSvg} alt="" />
        </div>
      </div>
    );
  }
}

TempBanner.propTypes = {
  height: PropTypes.number.isRequired,
  setHeight: PropTypes.func.isRequired,
};

export default TempBanner;
