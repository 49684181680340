/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
/* eslint-disable react/button-has-type, jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Bowser from 'bowser';
import queryString from 'query-string';

import inkblotLogoLight from '../images/_inkblot-logo-light.png';
import inkblotLogoDark from '../images/nav/inkblot-logo.svg';
import inkblotLogoMobile from '../images/nav/inkblot-logo-mobile.svg';
import SignUp from '../components/auth/SignUp';
import SignIn from '../components/auth/SignIn';
import HamburgerBlack from '../images/_hamburger.svg';
import HamburgerWhite from '../images/_hamburger-white.svg';
import close from '../images/x.svg';
import { unauthAuditProgress } from '../utils/pageUtils';
import { logEvent, OPEN_SIGNUP, OPEN_SIGNIN } from '../utils/amplitude';
import TempBanner from './TempBanner';
import { COUNTRY, OTHER_MARKETING_URL } from '../utils/environment';
import CountrySelectModal from './CountrySelectModal';
import CanadaFlag from '../images/nav/canada-flag.png';
import UsFlag from '../images/nav/us-flag.png';

const Store_Key_Country = 'selected-country-code';

const navItems = [
  {
    key: 'navigation.home',
    defaultMessage: 'Home',
    linkTo: '/',
    type: 'internal',
  },
  {
    key: 'navigation.about',
    type: 'internal',
    defaultMessage: 'About',
    linkTo: '/about',
  },
  {
    key: 'navigation.business',
    type: 'external',
    defaultMessage: 'Business',
    linkTo: 'https://business.inkblottherapy.com',
  },
  {
    key: 'navigation.mediatation',
    type: 'internal',
    defaultMessage: 'Meditation',
    linkTo: '/meditation',
  },
];

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigationShow: false,
      signUpOpen: false,
      signInOpen: false,
      isScrollDown: this.getScrollTop() > 60,
      tempBannerHeight: 40,
      countrySelectModalOpen: false,
    };
  }

  componentWillMount() {
    this.closeAuths();
  }

  componentDidMount() {
    const { location } = this.props;

    window.addEventListener('scroll', () => {
      this.setState({ isScrollDown: this.getScrollTop() > 60 });
    });

    const params = queryString.parse(location.search);
    const signUp = params.signup === 'true';
    const signIn = params.signin === 'true';

    if (signUp) {
      localStorage.setItem('signUp', 'open');
      this.setState({ signUpOpen: true });
    }
    if (signIn) {
      localStorage.setItem('signIn', 'open');
      this.setState({ signInOpen: true });
    }

    localStorage.getItem('signIn');
    localStorage.getItem('signUp');

    const isCountrySelected = params.country_selected === 'true' || false;
    if (isCountrySelected) {
      localStorage.setItem(Store_Key_Country, COUNTRY);

      const sendingParams = { ...params };
      delete sendingParams.country_selected;
      const sendingQuery = queryString.stringify(sendingParams);
      navigate(sendingQuery ? `/?${queryString.stringify(sendingParams)}` : '/');
    } else {
      const storedCode = localStorage.getItem(Store_Key_Country);
      if (storedCode && storedCode !== COUNTRY) {
        const sendingParams = {
          ...params,
          country_selected: true,
        };
        const sendingQuery = queryString.stringify(sendingParams);
        window.location.href = sendingQuery
          ? `${OTHER_MARKETING_URL}/?${sendingQuery}`
          : `${OTHER_MARKETING_URL}/`;
      }
    }
  }

  componentDidUpdate(_prevProps, prevState) {
    const { signUpOpen, signInOpen } = this.state;

    if (signUpOpen && signUpOpen !== prevState.signUpOpen) {
      logEvent(OPEN_SIGNUP, { page: '' });
      unauthAuditProgress('', 'open_signup');
    }

    if (signInOpen && signInOpen !== prevState.signInOpen) {
      logEvent(OPEN_SIGNIN, { page: '' });
      unauthAuditProgress('', 'open_signin');
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.getScrollTop, false);
  }

  setLanguage = lang => {
    localStorage.setItem('selectedLanguage', lang);
    window.location.reload();
  };

  getScrollTop = () =>
    (window.pageYOffset !== undefined
      ? window.pageYOffset
      : (document.documentElement || document.body.parentNode || document.body)
        .scrollTop);

  setTempBannerHeight = height => this.setState({ tempBannerHeight: height });

  setCountryCode = code => {
    this.closeCountrySelectModal();
    localStorage.setItem(Store_Key_Country, code);
    if (code !== COUNTRY) {
      window.location.href = `${OTHER_MARKETING_URL}/?country_selected=true`;
    }
  };

  toggleElements = element => {
    this.setState(prevState => ({ [element]: !prevState[element] }));
  };

  toggleSignInDrawer = () => {
    this.setState({
      navigationShow: false,
      signInOpen: true,
      signUpOpen: false,
    });
    localStorage.setItem('signIn', 'open');
    localStorage.setItem('signUp', 'close');
    this.forceUpdate();
  };

  toggleSignUpDrawer = () => {
    this.setState({
      navigationShow: false,
      signInOpen: false,
      signUpOpen: true,
    });
    localStorage.setItem('signIn', 'close');
    localStorage.setItem('signUp', 'open');
    this.forceUpdate();
  };

  closeAuths = () => {
    this.setState({
      signInOpen: false,
      signUpOpen: false,
    });
    localStorage.setItem('signIn', 'close');
    localStorage.setItem('signUp', 'close');
    document.getElementById('___gatsby').style.right = '0px';
    this.forceUpdate();
  };

  openCountrySelectModal = () =>
    this.setState({ countrySelectModalOpen: true });

  closeCountrySelectModal = () =>
    this.setState({ countrySelectModalOpen: false });

  filterNavItems = () => {
    const frenchSelected = localStorage.getItem('selectedLanguage') === 'fr';

    return frenchSelected ? navItems.slice(0, 3) : navItems;
  };

  renderFlagButton = () => (
    <div className="country-flag" onClick={() => this.openCountrySelectModal()}>
      <div>
        <img src={COUNTRY === 'US' ? UsFlag : CanadaFlag} alt="country flag" />
      </div>
    </div>
  );

  renderLinks = items => (
    <div className="nav-item-container">
      {items.map((item, i) => (
        <div key={`key-${i + 1}`}>
          {item.type === 'internal' && (
            <Link
              title={item.label}
              to={item.linkTo}
              onClick={() => this.setState({ navigationShow: false })}
            >
              <div
                className={
                  this.props.location.pathname === item.linkTo
                    ? 'nav-item-current'
                    : 'nav-item'
                }
              >
                <FormattedMessage
                  id={item.key}
                  defaultMessage={item.defaultMessage}
                />
              </div>
            </Link>
          )}
          {item.type === 'external' && (
            <a
              title={item.label}
              href={item.linkTo}
              onClick={() => this.setState({ navigationShow: false })}
            >
              <div
                className={
                  this.props.location.pathname === item.linkTo
                    ? 'nav-item-current'
                    : 'nav-item'
                }
              >
                <FormattedMessage
                  id={item.key}
                  defaultMessage={item.defaultMessage}
                />
              </div>
            </a>
          )}
        </div>
      ))}
      <div className="auth-buttons">
        <div>
          <span
            className="nav-item"
            onClick={() => {
              this.setState({ navigationShow: false, signInOpen: true });
              localStorage.setItem('signIn', 'open');
            }}
            role="presentation"
          >
            <FormattedMessage id="navigation.signIn" defaultMessage="Sign In" />
          </span>
        </div>

        <button
          className="medium-blue-button"
          onClick={() => {
            this.setState({ navigationShow: false, signUpOpen: true });
            localStorage.setItem('signUp', 'open');
          }}
        >
          <FormattedMessage
            id="navigation.createMyAccount"
            defaultMessage="Get Started"
          />
        </button>
      </div>
    </div>
  );

  renderTopBar = () => {
    const { tempBannerHeight } = this.state;
    return (
      <div
        className="top-navigation-bar"
        style={
          localStorage.getItem('selectedLanguage') !== 'fr'
            ? { top: tempBannerHeight - 1 }
            : {}
        }
      >
        {this.renderFlagButton()}
        <div className="email-support">
          <FormattedHTMLMessage
            tagName="span"
            id="navigation.emailSupport"
            defaultMessage="Have a question? Send us an email to <a href='mailto:support@inkblottherapy.com'>support@inkblottherapy.com</a>"
          />
        </div>
        <div>
          <a href="https://practitioner.inkblottherapy.com/">
            <FormattedHTMLMessage
              tagName="span"
              id="navigation.forPractitioners"
              defaultMessage="Inkblot for Practitioners"
            />
          </a>
          <div className="en-fr">
            {localStorage.getItem('selectedLanguage') === 'en' ? (
              <div>
                <span>EN</span>
                <span
                  role="presentation"
                  onClick={() => this.setLanguage('fr')}
                  style={{ fontWeight: 300 }}
                >
                  FR
                </span>
              </div>
            ) : (
              <div>
                <span
                  role="presentation"
                  onClick={() => this.setLanguage('en')}
                  style={{ fontWeight: 300 }}
                >
                  EN
                </span>
                <span>FR</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  renderNavBar = () => {
    const {
      navigationShow,
      isScrollDown,
      signInOpen,
      signUpOpen,
      tempBannerHeight,
    } = this.state;
    const { location } = this.props;

    let navTheme = '';

    let hamburger = HamburgerBlack;
    let inkblotLogo = inkblotLogoDark;
    if (location.pathname === '/avail' && !isScrollDown) {
      navTheme = 'navigation-bar-blue';
      inkblotLogo = inkblotLogoLight;
      hamburger = HamburgerWhite;
    }

    const signIn = localStorage.getItem('signIn') === 'open' || signInOpen;
    const signUp = localStorage.getItem('signUp') === 'open' || signUpOpen;

    return (
      <div
        className={`navigation-bar-container ${navTheme}`}
        style={
          localStorage.getItem('selectedLanguage') !== 'fr'
            ? { marginTop: tempBannerHeight + 51 }
            : {}
        }
      >
        <Link to="/">
          <img
            className="ink-logo"
            title="Inkblot"
            src={inkblotLogo}
            alt="Logo"
          />
        </Link>
        <div className="navigation-bar-right">
          {this.renderLinks(this.filterNavItems())}
        </div>
        <div className="navigation-bar-right-mobile">
          <div
            role="presentation"
            onClick={() => this.toggleElements('navigationShow')}
          >
            <img
              style={{ marginTop: '5px', cursor: 'pointer' }}
              src={hamburger}
              alt="hamburger"
            />
          </div>
        </div>
        <div
          style={navigationShow ? { height: '100%' } : { height: '0%' }}
          className="navigation-overlay-mobile"
        >
          <div
            role="presentation"
            onClick={() => this.toggleElements('navigationShow')}
          >
            <img className="navigation-mobile-close" src={close} alt="" />
          </div>
          <div className="navigation-overlay-mobile-content">
            <Link to="/">
              <img
                className="ink-logo"
                title="Inkblot"
                src={inkblotLogoMobile}
                alt="Logo"
              />
            </Link>
            {this.renderLinks(this.filterNavItems())}
            <div className="language-buttons">
              <span
                className={
                  localStorage.getItem('selectedLanguage') === 'en'
                    ? 'nav-item-current'
                    : 'nav-item'
                }
                role="presentation"
                onClick={() => this.setLanguage('en')}
              >
                EN
              </span>
              <span
                className={
                  localStorage.getItem('selectedLanguage') === 'fr'
                    ? 'nav-item-current'
                    : 'nav-item'
                }
                role="presentation"
                onClick={() => this.setLanguage('fr')}
              >
                FR
              </span>
            </div>
            <a
              title="Inkblot for Practitioner"
              href="https://practitioner.inkblottherapy.com/"
              onClick={() => this.setState({ navigationShow: false })}
              className="for-practitioners"
            >
              <div style={{ fontSize: '19px' }}>
                <FormattedMessage
                  id="navigation.forPractitioners"
                  defaultMessage="Inkblot for Practitioners"
                />
              </div>
            </a>
          </div>
        </div>
        <SignIn
          signInOpen={signIn}
          toggleSignInDrawer={() => this.closeAuths()}
          toggleSignUpDrawer={this.toggleSignUpDrawer}
        />
        <SignUp
          signUpOpen={signUp === true}
          toggleSignInDrawer={this.toggleSignInDrawer}
          toggleSignUpDrawer={() => this.closeAuths()}
        />
      </div>
    );
  };

  render() {
    const { tempBannerHeight, countrySelectModalOpen } = this.state;

    return (
      <div>
        {/* Temporarily replacing covid banner with maintenance banner */}
        {localStorage.getItem('selectedLanguage') !== 'fr' && (
          <TempBanner
            height={tempBannerHeight}
            setHeight={this.setTempBannerHeight}
          />
        )}
        {this.renderTopBar()}
        {this.renderNavBar()}
        {countrySelectModalOpen && (
          <CountrySelectModal
            onSelect={this.setCountryCode}
            onClose={this.closeCountrySelectModal}
          />
        )}
      </div>
    );
  }
}

Navigation.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Navigation;
