import _ from 'lodash';
import queryString from 'query-string';
import axios from 'axios';

import companies from '../pagePartials/custom/companies';
import corps from '../pagePartials/corporate-nav-page/companies';
import referrals from '../components/ReferralLanding/referrals';
import schools from '../pagePartials/gallivan/schools';
import edit from '../images/_myWellnessEdit.svg';
import female from '../images/_myWellnessFemale.svg';
import clock from '../images/_myWellnessClock.svg';
import awake from '../images/_myWellnessAwake.svg';
import { SERVER_URL } from './environment';

// Custom landing page helpers

const invalidCompany = {
  marketingURL: 'invalid_url',
  path: '',
  title: 'Invalid Company',
  logo: '',
};

const invalidReferral = {
  marketingURL: 'invalid_url',
  path: '',
  title: 'Invalid Referral',
  logo: '',
};

export const whichCompany = () => {
  let location = null;
  let name = null;

  if (typeof window !== 'undefined') {
    ({ location } = window);
  }

  if (location && location.pathname && location.pathname.split('/')[1]) {
    // eslint-disable-next-line prefer-destructuring
    name = location.pathname.split('/')[1];
  }

  if (name) {
    for (let i = 0; i < companies.length; i += 1) {
      if (name.toLowerCase() === companies[i].marketingURL.toLowerCase()) {
        return companies[i];
      }
    }
  }

  if (location && location.search) {
    const params = queryString.parse(location.search);
    if (params.eap) {
      name = params.eap;
    }
  }

  if (name) {
    for (let i = 0; i < companies.length; i += 1) {
      if (name.toLowerCase() === companies[i].marketingURL.toLowerCase()) {
        return companies[i];
      }
    }
  }

  return invalidCompany;
};

export const whichCorp = () => {
  let location = null;
  let name = null;

  if (typeof window !== 'undefined') {
    ({ location } = window);
  }

  if (location && location.pathname && location.pathname.split('/')[1]) {
    // eslint-disable-next-line prefer-destructuring
    name = location.pathname.split('/')[1];
  }

  if (name) {
    for (let i = 0; i < corps.length; i += 1) {
      if (name.toLowerCase() === corps[i].marketingURL.toLowerCase()) {
        return corps[i];
      }
    }
  }

  return invalidCompany;
};

export const isCorporateLanding = pathname => {
  const urls = _.map(companies, company => company.marketingURL);
  let i;
  for (i = 0; i < urls.length; i += 1) {
    if (pathname.split('/')[1].toLowerCase() === urls[i].toLowerCase()) {
      return true;
    }
  }
  return false;
};

export const isCorporateNav = pathname => {
  const urls = _.map(corps, corp => corp.marketingURL);
  let i;
  for (i = 0; i < urls.length; i += 1) {
    if (pathname.split('/')[1].toLowerCase() === urls[i].toLowerCase()) {
      return true;
    }
  }
  return false;
};

export const isFullSignUp = pathname => {
  const search =
    typeof window !== 'undefined' &&
    window &&
    window.location &&
    window.location.search;
  const fullSignUps = _.filter(companies, { full: true });
  const full = queryString.parse(search).eap || pathname.split('/')[1];
  let i;
  for (i = 0; i < fullSignUps.length; i += 1) {
    if (full.toLowerCase() === fullSignUps[i].marketingURL.toLowerCase()) {
      return true;
    }
  }
  return false;
};

export const isFullyGatedSignUp = pathname => {
  const search =
    typeof window !== 'undefined' &&
    window &&
    window.location &&
    window.location.search;
  const fullSignUps = _.filter(companies, { fullyGated: true });
  const full = queryString.parse(search).eap || pathname.split('/')[1];
  let i;
  for (i = 0; i < fullSignUps.length; i += 1) {
    if (full.toLowerCase() === fullSignUps[i].marketingURL.toLowerCase()) {
      return true;
    }
  }
  return false;
};

export const isPartialSignUp = pathname => {
  const search =
    typeof window !== 'undefined' &&
    window &&
    window.location &&
    window.location.search;
  const partialSignUps = _.filter(companies, { partial: true });
  const partial = queryString.parse(search).eap || pathname.split('/')[1];
  let i;
  for (i = 0; i < partialSignUps.length; i += 1) {
    if (
      partial.toLowerCase() === partialSignUps[i].marketingURL.toLowerCase()
    ) {
      return true;
    }
  }
  return false;
};

export const isNoneSignUp = pathname => {
  const search =
    typeof window !== 'undefined' &&
    window &&
    window.location &&
    window.location.search;
  const noneSignUps = _.filter(companies, { noneSignup: true });
  const none = queryString.parse(search).eap || pathname.split('/')[1];
  let i;
  for (i = 0; i < noneSignUps.length; i += 1) {
    if (none.toLowerCase() === noneSignUps[i].marketingURL.toLowerCase()) {
      return true;
    }
  }
  return false;
};

export const whichReferral = () => {
  let location = null;
  let name = null;

  if (typeof window !== 'undefined') {
    ({ location } = window);
  }

  if (location && location.pathname && location.pathname.split('/')[1]) {
    // eslint-disable-next-line prefer-destructuring
    name = location.pathname.split('/')[1];
  }

  if (name) {
    for (let i = 0; i < referrals.length; i += 1) {
      if (name.toLowerCase() === referrals[i].marketingURL.toLowerCase()) {
        return referrals[i];
      }
    }
  }

  return invalidReferral;
};

export const isReferralLanding = pathname => {
  for (let i = 0; i < referrals.length; i += 1) {
    if (
      pathname.split('/')[1].toLowerCase() ===
      referrals[i].marketingURL.toLowerCase()
    ) {
      return true;
    }
  }
  return false;
};

// Gallivan helpers
export const circleData = [
  {
    id: 1,
    image: edit,
    text: 'Fill out our simple survey.',
  },
  {
    id: 2,
    image: female,
    text: 'Choose a counsellor matched specifically to you.',
  },
  {
    id: 3,
    image: clock,
    text: 'Schedule your first free session. No payment info needed.',
  },
  {
    id: 4,
    image: awake,
    text: 'Speak with your Counsellor and feel better.',
  },
];

export const securityData = [
  {
    id: 'eap.onlineServices',
    defaultMessage:
      'We follow strict Canadian regulations for online health services.',
  },
  {
    id: 'eap.toronto',
    defaultMessage:
      'All information is fully encrypted on a regularly audited server in Toronto.',
  },
  {
    id: 'eap.onlyYou',
    defaultMessage:
      'Only you and your counsellor have access to your mental health information.',
  },
  {
    id: 'eap.counsellors',
    defaultMessage:
      'All counsellors reside in Canada, are highly experienced, and are regulated professionals.',
  },
];

export const securityGall = [
  'We follow strict Canadian regulations for online health services.',
  'All information is fully encrypted on a regularly audited server in Toronto.',
  'Only you and your counsellor have access to your mental health information.',
  'All counsellors reside in Canada, are highly experienced, and are regulated professionals.',
];

// Requiring function causes error during builds
// as the code tries to reference window
// const module = require("module"); // Error

// // Wrap the require in check for window

const invalidSchool = {
  key: 'invalid_school',
  title: 'Invalid School',
  benefitsText: 'Invalid School',
  myWellnessUrl: '',
  insuranceInformationUrl: '',
  mentalHealthAssessmentUrl: '',
  inkblotUrl: '',
  inkblotSignUpUrl: '',
  logo: '',
};

export const whichSchool = () =>
  (typeof window !== 'undefined'
    ? _.find(schools, [
      'key',
      window.location.pathname.split('/')[1].toLowerCase(),
    ])
    : invalidSchool);

export const isGallivanPage = pathname => {
  const urls = _.map(schools, school => school.key);
  let i;
  for (i = 0; i < urls.length; i += 1) {
    if (pathname.split('/')[1].toLowerCase() === urls[i].toLowerCase()) {
      return true;
    }
  }
  return false;
};

// Auditing progress helpers

export const auditProgress = (
  page,
  choice,
  token,
  url = SERVER_URL,
  callback = () => {},
) => {
  axios
    .post(
      `${url}/api/v2/audit_progresses`,
      { page, choice: [choice] },
      { headers: { Authorization: token } },
    )
    .then(() => callback())
    .catch(() => callback());
};

export const unauthAuditProgress = (page, choice, callback = () => {}) => {
  axios
    .post(`${SERVER_URL}/api/v2/audit_progresses/unauth_create`, {
      page,
      choice: [choice],
    })
    .then(() => callback())
    .catch(() => callback());
};

export const getCountry = (callback = () => {}) => {
  axios
    .get('http://ip-api.com/json')
    .then(resp => callback(resp))
    .catch(resp => callback(resp));
};

export const parseEapCodeFromPath = () => {
  const params = queryString.parse(window.location.search);
  return (params.eap || '').toLowerCase();
};

// Reseller helpers

export const isSunLife = pathname => {
  if (pathname.split('/')[1].includes('sunlife')) {
    return true;
  }
  return false;
};

export const isLeagueX = pathname => {
  if (pathname.split('/')[1].includes('leaguex')) {
    return true;
  }
  return false;
};

export const isMLF = pathname => {
  if (pathname.split('/')[1].includes('mlf')) {
    return true;
  }
  return false;
};

export const isLeslie = pathname => {
  if (pathname.split('/')[1].includes('care')) {
    return true;
  }
  return false;
};

export const isOmers = pathname => {
  if (pathname.split('/')[1].includes('omers')) {
    return true;
  }
  return false;
};

export const isPPCHealthAkira = pathname => {
  if (pathname.split('/')[1].includes('ppc_health_akira')) {
    return true;
  }
  return false;
};

export const isPPCHealthMaple = pathname => {
  if (pathname.split('/')[1].includes('ppc_health_maple')) {
    return true;
  }
  return false;
};

export const isPPCHealthLifeInc = pathname => {
  if (pathname.split('/')[1].includes('ppc_health_life_inc')) {
    return true;
  }
  return false;
};

export const isPPCHealthBCI = pathname => {
  if (pathname.split('/')[1].includes('ppc_health_bci')) {
    return true;
  }
  return false;
};

export const isCSA = pathname => {
  if (pathname.split('/')[1].includes('csa_land')) {
    return true;
  }
  return false;
};

export const isATB = pathname => {
  if (pathname.split('/')[1].includes('atb_land')) {
    return true;
  }
  return false;
};

export const isPPCHealthBimbo = pathname => {
  if (pathname.split('/')[1].includes('ppc_health_bimbo')) {
    return true;
  }
  return false;
};

export const noIntercom = pathname => {
  const urls = [
    'csa_land',
    'ppc_health_bci',
    'ppc_health_life_inc',
    'mlf',
    'ppc_health_maple',
    'ppc_health_akira',
    'atb_land',
    'ppc_health_bimbo',
    'fugro_nav',
    'yr_nav',
  ];
  let i;
  for (i = 0; i < urls.length; i += 1) {
    if (pathname.split('/')[1].toLowerCase() === urls[i]) {
      return false;
    }
  }
  if (isCorporateNav(pathname)) return false;
  return true;
};
