import Jody from "../../images/business/JodyCircle.png";
import Alisha from "../../images/business/AlishaCircle.png";
import Oscar from "../../images/business/OscarCircle.png";

import Meditate from "../../images/business/Meditate.png";
import LovedOnes from "../../images/business/LovedOnes.png";
import Exercise from "../../images/business/Exercise.png";
import Book from "../../images/business/Book.png";
import Nap from "../../images/business/Nap.png";

import LeagueLogo from "../../images/business/LeagueLogo.png";
import FiveHundredPxLogo from "../../images/business/500pxLogo.png";
import TribalScaleLogo from "../../images/business/TribalScaleLogo.png";
import DMZLogo from "../../images/business/DMZLogo.png";

export const employerData = [
  {
    id: 1,
    item: "Notice a significant increase in employee productivity"
  },
  {
    id: 2,
    item: "Have reduced employee turnover & are more attractive to new talent"
  },
  {
    id: 3,
    item: "More effectively manage employee mental health issues"
  }
];

export const peopleData = [
  {
    id: 0,
    name: "Jody",
    nameId: "business.jody",
    nameDefaultMessage: "Jody's Story",
    image: Jody,
    titleId: "business.thePerfectManager",
    titleDefaultMessage: "The 'Perfect' Manager ",
    solutionId: "business.jodySolution",
    solutionDefaultMessage: "Find Jody A Solution",
    indepthDescription: {
      htmlEN:
        "Jody is in middle management at EVRY Org. She's been working there as an Account Manager for the past seven years. Jody is beloved by all her colleagues, and is proud of her work. <b class=\"indepth-titleDefaultMessage\"> <br><br> She wants to continue to be the best possible manager she can, however, sometimes the stress of being 'perfect' is overwhelming.</b><br><br>  She's wondering what it will take to make the jump to upper management without losing the great relationship she has with her team.",
      htmlFR:
        'Jody est une gestionnaire intermédiaire chez EVRY Org. Elle y travaille depuis sept ans en tant que gestionnaire des comptes. Jody est appréciée par tous ses collègues et elle est fière de son travail.<b class="indepth-titleDefaultMessage"> <br><br> Elle désire continuer à être la meilleure gestionnaire possible, cependant le stress d’être « parfaite » est parfois accablant.</b><br><br> Elle se demande ce qu’il faudrait pour être promu à un poste de cadre supérieur, et ce, sans perdre l’excellente relation qu’elle a avec son équipe.'
    }
  },
  {
    id: 1,
    name: "Alisha",
    nameId: "business.alisha",
    nameDefaultMessage: "Alisha's Story",
    image: Alisha,
    titleId: "business.theWorkingMom",
    titleDefaultMessage: "The Working Mom",
    solutionId: "business.alishaSolution",
    solutionDefaultMessage: "Find Alish A Solution",
    indepthDescription: {
      htmlEN:
        'Alisha rushes to EVRY Org. every morning to start her full-time marketing job. She is a hard worker who skips lunch so that she can get as much done as possible by 5pm in order to get home to her two year old daughter. <b class="indepth-titleDefaultMessage"> <br><br>Alisha struggles with work-life balance. She loves her job but she also wants to spend more time with her daughter. Between the demands of the two, she has no time to herself and is constantly run down. </b><br><br> There are days when Alisha wants to stay in bed and sleep all day, which makes her feel guilty. She worries that she&quot;s not good enough at work and as a mom and this leaves her feeling hopeless.',
      htmlFR:
        'Alisha se précipite chez EVRY Org. tous les matins, où elle occupe un poste à temps plein en marketing. C’est une travailleuse acharnée qui saute la pause repas, effectuer le plus de travail possible avant 17 h, afin de rentrer à la maison pour être auprès de sa fille de deux ans.  <b class="indepth-titleDefaultMessage"> <br><br> Alisha a des difficultés à concilier travail et vie personnelle. Elle adore son travail, mais elle veut aussi passer plus de temps avec sa fille. Entre les demandes des deux, elle n’a aucun temps libre pour elle-même et elle se sent constamment surmenée. </b><br><br> Certains jours, Alisha voudrait dormir toute la journée, ce qui la fait se sentir coupable. Elle s’inquiète de ne pas être à la hauteur au travail et comme mère, et cela la fait sentir impuissante. '
    }
  },
  {
    id: 2,
    name: "Oscar",
    nameId: "business.oscar",
    nameDefaultMessage: "Oscar's Story",
    image: Oscar,
    titleId: "business.theRecentGrad",
    titleDefaultMessage: "The Recent Grad",
    solutionId: "business.oscarSolution",
    solutionDefaultMessage: "Find Oscar A Solution",
    indepthDescription: {
      htmlEN:
        'Oscar recently graduated from University and has an account assistant role at EVRY Org. He is uncertain about his future with the organization and feels anxious most of the time. <br><br> <b class="indepth-titleDefaultMessage">  When Oscar&#39;s boss asks him to correct a task, he takes it as a criticism and assumes that he is not good at his job. He has a hard time fitting in and without frequent approval, his negative thinking takes over. </b><br><br> Due to frequent panic attacks, Oscar was put on medication. This has helped alleviate the attacks but he still feels scared, worries constantly and has trouble sleeping. ',
      htmlFR:
        'Oscar a récemment obtenu un diplôme universitaire et il travaille comme adjoint aux comptes chez EVRY Org. Il est incertain de son avenir au sein de l’entreprise et se sent anxieux la plupart du temps.<br><br> <b class="indepth-titleDefaultMessage"> Lorsque le supérieur d’Oscar lui demande de rectifier une tâche, il le prend comme une critique et croit qu’il n’est pas à la hauteur. Il a de la difficulté à s’intégrer et sans approbations fréquentes, sa pensée négative prend le dessus. </b><br><br> À cause de ses crises de panique fréquentes, une médication a été prescrite à Oscar. Cela a aidé à atténuer les crises, mais il a toujours peur et s’inquiète constamment, en plus d’avoir de la difficulté à dormir.'
    }
  }
];

export const services = [
  {
    titleId: "business.lifeCoaching",
    titleDefaultMessage: "Life Coaching",
    points: [
      {
        pointId: "business.certifiedLifeCoaches",
        defaultMessage: "Certified Canadian life coaches."
      },
      {
        pointId: "business.focusedOnGoal",
        defaultMessage:
          "Focused on goal setting, lifestyle changes & increased productivity"
      }
    ]
  },
  {
    titleId: "business.managerTrainingSupport",
    titleDefaultMessage: "Manager Training & Support",
    points: [
      {
        pointId: "business.regularGroupSession",
        defaultMessage:
          "Group session training on various mental health related topics."
      },
      {
        pointId: "business.abilityToDiscuss",
        defaultMessage:
          "Ability to discuss specific cases during small group sessions."
      }
    ]
  },
  {
    titleId: "business.largeInteractiveGroupSessions",
    titleDefaultMessage: "Large Interactive Group Sessions",
    points: [
      {
        pointId: "business.focusedOnCommonComplaints",
        defaultMessage:
          "Focused on common complaints such as sleep difficulties, work-life balance, stress etc."
      },
      {
        pointId: "business.featuresBreakOut",
        defaultMessage: "Features 'break-out' rooms and trained moderators."
      }
    ]
  },
  {
    titleId: "business.oneOnOneMentalHealth",
    titleDefaultMessage: "One-on-One Mental Health Counselling",
    points: [
      {
        pointId: "business.expertOneonOneTherapy",
        defaultMessage:
          "Expert one-on-one therapy with validated metric-based quality monitoring."
      },
      {
        pointId: "business.highSatisfaction",
        defaultMessage: "High satisfaction and symptom reduction scores"
      }
    ]
  },
  {
    titleId: "business.familyCouplesCounselling",
    titleDefaultMessage: "Family & Couples counselling",
    points: [
      {
        pointId: "business.certifiedFamilyAndCouples",
        defaultMessage: "Certified family & couples counsellors."
      },
      {
        pointId: "business.membersCouldBeInDifferentLocations",
        defaultMessage: "Members could be in different locations."
      }
    ]
  },
  {
    titleId: "business.smallInteractiveGroupSessions",
    titleDefaultMessage: "Small Interactive Group Sessions",
    points: [
      {
        pointId: "business.focusedOnCommonComplaints",
        defaultMessage:
          "Focused on common complaints such as sleep difficulties, work-life balance, stress etc."
      },
      {
        pointId: "business.featuresBreakOutRooms",
        defaultMessage: "Features 'break-out' rooms and trained moderators."
      }
    ]
  },
  // {
  //   titleId: 'business.oneOnOneMentalHealth',
  //   titleDefaultMessage: 'One-on-one Mental Health Counselling',
  //   points: [
  //     {
  //       pointId: 'business.certifiedCanadianLifeCoaches',
  //       defaultMessage: 'Certified Canadian life coaches.',
  //     },
  //     {
  //       pointId: 'business.focusedOnGoalSetting',
  //       defaultMessage: 'Focused on goal setting, lifestyle changes & increased productivity',
  //     },
  //   ],
  // },
  {
    titleId: "business.twentyFourSevenCrisis",
    titleDefaultMessage: "24/7 Crisis Support",
    points: [
      {
        pointId: "business.1800phoneNumber",
        defaultMessage: "1-800 phone number with trained crisis specialist."
      }
    ]
  },
  {
    titleId: "business.addOnServices",
    titleDefaultMessage: "Add-on services",
    points: [
      {
        pointId: "business.medicalAssessmentsAndMedication",
        defaultMessage:
          "Medical Assessments & Medication Adjustments (Ontario only)"
      }
    ]
  }
];

export const practices = [
  {
    image: Book,
    titleId: "business.relaxWithAGoodBook",
    defaultMessage: "Relax with a good book"
  },
  {
    image: Meditate,
    titleId: "business.meditate",
    defaultMessage: "Meditate"
  },
  {
    image: Exercise,
    titleId: "business.exercise",
    defaultMessage: "Exercise"
  },
  {
    image: LovedOnes,
    titleId: "business.spendTimeWithLovedOnes",
    defaultMessage: "Spend time with loved ones"
  },
  {
    image: Nap,
    titleId: "business.takeANap",
    defaultMessage: "Take a nap"
  }
];

export const companies = [
  { id: 1, nameId: "League", image: LeagueLogo },
  { id: 2, nameId: "Tribal Scale", image: TribalScaleLogo },
  { id: 3, nameId: "DMZ", image: DMZLogo },
  { id: 4, nameId: "500px", image: FiveHundredPxLogo }
];

export const isBusiness = pathname => {
  if (pathname.split("/")[1].includes("business")) {
    return true;
  }
  return false;
};

export const isEvents = pathname => {
  if (pathname.split("/")[1].includes("events")) {
    return true;
  }
  return false;
};
