import React, { Component } from 'react';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { signUpData } from '../../pagePartials/auth';
import DataLoadingSpinner from '../DataLoadingSpinner';
import X from '../../images/dark-x.svg';
import UnitedStates from '../../images/united-states.png';
import Canada from '../../images/canada.png';
import { SERVER_URL, THERAPY_URL, OTHER_SERVER_URL, OTHER_THERAPY_URL } from '../../utils/environment';
import { auditProgress } from '../../utils/pageUtils';
import { logEvent, COMPLETED_SIGNUP } from '../../utils/amplitude';

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signUpDisabled: true,
      signUpError: null,
      error: {},
      recentlyUpdated: null,
      loading: false,
      errCountry: null,
      companies: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.recentlyUpdated !== prevState.recentlyUpdated) {
      this.validateForm();
    }
  }

  emailExists = email => {
    axios
      .get(`${SERVER_URL}/api/signup/check_email?email=${email}`)
      .then(() => {})
      .catch(e => {
        this.setState({
          error: {
            ...this.state.error,
            email: e.response.data.error,
          },
        });
      });
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      recentlyUpdated: name,
    });
  };

  handleChangeRadio = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeSelect = selectedOption => {
    this.setState({ company_name: selectedOption.label, recentlyUpdated: 'company_name' });
  }

  searchCompanies = newQuery => {
    if (newQuery === '') {
      this.setState({ companies: [] });
    } else {
      axios
        .get(`${SERVER_URL}/api/v2/league/search?query=${newQuery}`)
        .then(response => this.setState({ companies: response.data }))
        .catch(error => {
          throw error.response;
        });
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    this.signUp();
  };

  signUp = () => {
    const values = _.omit(this.state, [
      'signUpDisabled',
      'error',
      'signUpError',
      'recentlyUpdated',
      'other_referral_source',
      'errCountry',
      'loading',
      'companies',
    ]);

    const mergedValues = { ...values, source: 'therapy' };

    axios
      .post(`${(mergedValues.country === 'United States' ? OTHER_SERVER_URL : SERVER_URL)}/api/v2/league`, mergedValues)
      .then(response => {
        const token = response.data.access_token;
        this.setState({ loading: true });
        logEvent(COMPLETED_SIGNUP, { page: '' });
        auditProgress('', 'completed_signup', (mergedValues.country === 'United States' ? OTHER_SERVER_URL : SERVER_URL), token, () =>
          window.location.replace(`${(mergedValues.country === 'United States' ? OTHER_THERAPY_URL : THERAPY_URL)}/redirect/${token}`));
      })
      .catch(e => {
        this.setState({ signUpError: e.response.data.error });
      });
  };

  switchDrawers = () => {
    this.props.toggleSignUpDrawer();
    this.props.toggleSignInDrawer();
  };

  validateForm = () => {
    const isFr = localStorage.getItem('selectedLanguage') === 'fr';
    const requiredField = isFr
      ? 'Ceci est un champ obligatoire'
      : 'This is a required field';
    const emailValid = isFr
      ? "L'email doit être valide"
      : 'Email must be valid';
    const passwordSix = isFr
      ? 'Le mot de passe doit être au moins de 6 caractères '
      : 'Password must be at least 6 characters';
    const passwordMatch = isFr
      ? 'Les mots de passe doivent correspondre'
      : 'Passwords must match';
    const {
      first_name,
      last_name,
      email,
      password,
      password_confirm,
      country,
      company_name,
      status,
    } = this.state;
    const error = {};

    if (first_name === '') {
      error.first_name = requiredField;
    }
    if (last_name === '') {
      error.last_name = requiredField;
    }
    if (email === '') {
      error.email = requiredField;
    }
    if (password === '') {
      error.password = requiredField;
    }
    if (company_name === '') {
      error.company_name = requiredField;
    }
    if (status === '') {
      error.status = requiredField;
    }

    if (country === '') {
      error.country = requiredField;
      this.setState({ errCountry: null });
    }

    if (email) {
      const validEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!validEmail.test(email)) {
        error.email = emailValid;
      }
      this.emailExists(email);
    }
    if (password) {
      if (password.length < 6) {
        error.password = passwordSix;
      } else if (password !== password_confirm) {
        error.password_confirm = passwordMatch;
      }
    }

    this.setState({
      recentlyUpdated: null,
      signUpDisabled:
        !_.isEmpty(error) ||
        !first_name ||
        !last_name ||
        !email ||
        !password ||
        !password_confirm ||
        !country ||
        !company_name ||
        !status,
      error,
    });
  };

  renderInputs = inputs => {
    const { error } = this.state;
    return (
      <div className="input-row">
        {_.map(inputs, ({
          id, name, defaultText, type, text, fullWidth,
          }) => (
            <div key={id} className={fullWidth ? 'full-width-input input-container' : 'input-container'}>
              <p className="label">
                <FormattedMessage id={text} />
              </p>
              <input
                id={name}
                onChange={event => this.handleChange(event)}
                type={type}
                name={name}
                placeholder={defaultText}
                value={this.state[name]}
              />
              <p className={`error-text ${error[name] ? '' : 'hidden'}`}>
                {error[name]}
              </p>
            </div>
        ))}
      </div>
    );
  };

  renderCompanySignup = () => {
    const { companies, error: { company_name, status } } = this.state;
    return (
      <div className="league-signup">
        <div className="input-row">
          <div className="input-container">
            <p className="label">
              <FormattedMessage id="auth.companySelection" defaultMessage="Company" />
            </p>
            <Select
              options={companies}
              classNamePrefix="league-dropdown"
              placeholder="Start typing"
              isSearchable
              name="company"
              onInputChange={newQuery => this.searchCompanies(newQuery)}
              onChange={this.handleChangeSelect}
            />
            <p
              className={`error-text ${
                company_name ? '' : 'hidden'
              }`}
            >
              This is a required field
            </p>
          </div>
          <div className="input-container">
            <p className="label">
              <FormattedMessage id="auth.roleSelection" defaultMessage="Status" />
            </p>
            <select
              className="league-dropdown"
              onChange={value => this.handleChange(value)}
              type="text"
              name="status"
              defaultValue=""
            >
              <option value="">Select an option</option>
              <option value="1">Employee/Member</option>
              <option value="3">Spouse</option>
              <option value="4">Dependent</option>
            </select>
            <p
              className={`error-text ${
                status ? '' : 'hidden'
              }`}
            >
              This is a required field
            </p>
          </div>
        </div>
      </div>
    );
  }

  renderCountrySelect = () => {
    const {
      error,
      errCountry,
    } = this.state;

    const countrySelection = error.country;

    return (
      <div className="input-container">
        <p className="label">
          <FormattedMessage id="auth.countrySelection" />
        </p>
        <select
          className="referral-dropdown"
          onChange={value => this.handleChange(value)}
          type="text"
          name="country"
          defaultValue=""
        >
          <option value="">Please select an option in this list.</option>
          <option value="Canada">Canada</option>
          <option value="United States">United States</option>
        </select>
        <p className={`error-text ${countrySelection ? '' : 'hidden'}`} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {errCountry === 'USA' && (
            <a href="https://us.inkblottherapy.com">
              {countrySelection}
              <img src={UnitedStates} alt="united states" style={{ width: 20, marginLeft: 7 }} />
            </a>
          )}
          {errCountry === 'CANADA' && (
            <a href="https://inkblottherapy.com">
              {countrySelection}
              <img src={Canada} alt="canada" style={{ width: 20, marginLeft: 7 }} />
            </a>
          )}
        </p>
      </div>
    );
  }

  render() {
    const { signUpOpen } = this.props;
    const {
      signUpError,
      signUpDisabled,
      loading,
    } = this.state;

    const hasScrollbar =
      window.innerWidth > document.documentElement.clientWidth;

    // eslint-disable-next-line
    const authStyle = signUpOpen
      ? hasScrollbar
        ? { right: '-15px' }
        : { right: '0px' }
      : hasScrollbar
        ? { right: '-615px', boxShadow: 'none' }
        : { right: '-600px', boxShadow: 'none' };

    const signupButtonStyles = signUpDisabled
      ? 'center sign-up-button pink-gradient-button disabled-button'
      : 'center sign-up-button pink-gradient-button';

    const loaderStyling = loading
      ? { visibility: 'visible', marginBottom: '35px' }
      : { visibility: 'hidden' };
    return (
      <div>
        <div className="auth" style={authStyle}>
          <div className="sign-up">
            <h1 className="title">
              <FormattedMessage id="auth.signUp" defaultMessage="Sign Up" />
            </h1>
            <h2 className="subtitle">
              <FormattedMessage
                id="auth.alreadyHaveAnAccount"
                defaultMessage="Already have an account?&nbsp;"
              />
              <span
                className="subtitle-link"
                onClick={() => this.switchDrawers()}
                role="presentation"
              >
                <FormattedMessage id="auth.signIn" defaultMessage="Sign In" />
              </span>
            </h2>
            <form
              className="form-container sign-up-form"
              onSubmit={this.handleSubmit}
            >
              {this.renderInputs(signUpData.slice(0, 2))}
              {this.renderInputs(signUpData.slice(3, 4))}
              {this.renderInputs(signUpData.slice(4, 6))}
              {this.renderCountrySelect()}
              {this.renderCompanySignup()}
              <div>
                <p className={`error-text ${signUpError ? '' : 'hidden'}`}>
                  {signUpError}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <button
                  className={signupButtonStyles}
                  type="submit"
                  disabled={signUpDisabled}
                >
                  <FormattedMessage
                    id="auth.createMyAccount"
                    defaultMessage="CREATE MY ACCOUNT"
                  />
                </button>
                <div style={loaderStyling}>
                  <DataLoadingSpinner />
                </div>
              </div>
            </form>
            <div
              className="close"
              onClick={this.props.toggleSignUpDrawer}
              role="presentation"
            >
              <img src={X} alt="close" />
            </div>
          </div>
        </div>
        <div
          className="overlay"
          onClick={this.props.toggleSignUpDrawer}
          role="presentation"
          style={
            signUpOpen
              ? { visibility: 'visible', opacity: '1', right: '600px' }
              : { visibility: 'hidden', opacity: '0', right: 0 }
          }
        />
      </div>
    );
  }
}

SignUp.propTypes = {
  signUpOpen: PropTypes.bool.isRequired,
  toggleSignUpDrawer: PropTypes.func.isRequired,
  toggleSignInDrawer: PropTypes.func.isRequired,
};

SignUp.defaultProps = {};

export default SignUp;
