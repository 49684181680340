import React, { Component } from 'react';
import axios from 'axios';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { signUpData } from '../../../pagePartials/gallivan/signUpData';
import X from '../../../images/gallivan/x.svg';
import { whichSchool, auditProgress } from '../../../utils/pageUtils';
import { SERVER_URL, THERAPY_URL } from '../../../utils/environment';
import { logEvent, COMPLETED_SIGNUP } from '../../../utils/amplitude';

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signUpDisabled: true,
      signUpError: null,
      error: {},
      recentlyUpdated: null,
    };

    this.school = whichSchool();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.recentlyUpdated !== prevState.recentlyUpdated) {
      this.validateForm();
    }
  }

  emailExists = email => {
    axios
      .get(`${SERVER_URL}/api/signup/check_email?email=${email}`)
      .then(() => {})
      .catch(e => {
        this.setState({
          error: {
            ...this.state.error,
            email: e.response.data.error,
          },
        });
      });
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: name === 'dob' ? this.normalizeDate(value) : value,
      recentlyUpdated: name,
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.signUp();
  };

  signUp = () => {
    const values = _.omit(this.state, [
      'signUpDisabled',
      'error',
      'signUpError',
      'recentlyUpdated',
    ]);

    axios
      .post(`${SERVER_URL}/api/signup`, {
        ...values,
        school: this.school.value,
        source: 'therapy',
        gallivan_yn: true,
        corporate_account: this.school.key,
      })
      .then(response => {
        const token = response.data.access_token;
        const inkblotUrl = `${THERAPY_URL}/redirect/${token}`;
        logEvent(COMPLETED_SIGNUP, { page: this.school.key });
        auditProgress(this.school.key, 'completed_signup', token);
        window.location.replace(inkblotUrl);
      })
      .catch(e => {
        this.setState({ signUpError: e.response.data.error });
      });
  };

  switchDrawers = () => {
    this.props.toggleSignUpDrawer();
    this.props.toggleSignInDrawer();
  };

  validateForm = () => {
    const {
      first_name,
      last_name,
      email,
      password,
      password_confirm,
    } = this.state;
    const error = {};

    if (first_name === '') {
      error.first_name = 'This is a required field';
    }
    if (last_name === '') {
      error.last_name = 'This is a required field';
    }
    if (email === '') {
      error.email = 'This is a required field';
    }
    if (password === '') {
      error.password = 'This is a required field';
    }
    if (email) {
      const validEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!validEmail.test(email)) {
        error.email = 'Email must be valid';
      }
      this.emailExists(email);
    }
    if (password) {
      if (password.length < 6) {
        error.password = 'Password must be at least 6 characters';
      } else if (password !== password_confirm) {
        error.password_confirm = 'Passwords must match';
      }
    }

    this.setState({
      recentlyUpdated: null,
      error,
      signUpDisabled:
        !_.isEmpty(error) ||
        !first_name ||
        !last_name ||
        !email ||
        !password ||
        !password_confirm,
    });
  };

  handleChangeRadio = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  renderInputs = inputs => {
    const { error } = this.state;
    return (
      <div className="input-row">
        {_.map(inputs, ({
 id, name, defaultText, type, text, fullWidth,
}) => (
  <div
    key={id}
    className={
              fullWidth ? 'full-width-input input-container' : 'input-container'
            }
  >
    <p className="label">{text}</p>
    <input
      id={name}
      onChange={event => this.handleChange(event)}
      type={type}
      name={name}
      placeholder={defaultText}
      value={this.state[name]}
    />
    <p className={`error-text ${error[name] ? '' : 'hidden'}`}>
      {error[name]}
    </p>
  </div>
        ))}
      </div>
    );
  };

  render() {
    const { signUpOpen } = this.props;
    const { signUpError, signUpDisabled } = this.state;
    const hasScrollbar =
      window.innerWidth > document.documentElement.clientWidth;
    // eslint-disable-next-line
    const authStyle = signUpOpen
      ? hasScrollbar
        ? { right: '-15px' }
        : { right: '0px' }
      : hasScrollbar
        ? { right: '-615px', boxShadow: 'none' }
        : { right: '-600px', boxShadow: 'none' };

    return (
      <div>
        <MetaTags>
          <meta name="robots" content="noindex" />
        </MetaTags>
        <div className="gallivan-auth" style={authStyle}>
          <div className="sign-up">
            <h1 className="title">Sign Up</h1>
            <h2 className="subtitle">
              Already have an account?&nbsp;
              <span
                className="subtitle-link"
                onClick={() => this.switchDrawers()}
                role="presentation"
              >
                Sign in
              </span>
            </h2>
            <form
              className="form-container sign-up-form"
              onSubmit={this.handleSubmit}
            >
              {this.renderInputs(signUpData.slice(0, 2))}
              {this.renderInputs(signUpData.slice(3, 4))}
              {this.renderInputs(signUpData.slice(4, 6))}
              <div className="input-container">
                <p className="label">How did you hear about us?</p>
                <input
                  onChange={value => this.handleChange(value)}
                  type="text"
                  name="referral_source"
                  placeholder="Instagram, Pamphlet, Doctor, etc"
                />
                <p className={`error-text ${signUpError ? '' : 'hidden'}`}>
                  {signUpError}
                </p>
              </div>
              <button
                className={
                  signUpDisabled
                    ? 'center sign-up-button gallivan-button-green disabled-button'
                    : 'center sign-up-button gallivan-button-green'
                }
                type="submit"
                disabled={signUpDisabled}
              >
                <p className="">CREATE MY ACCOUNT</p>
              </button>
            </form>
            <div
              className="close"
              onClick={this.props.toggleSignUpDrawer}
              role="presentation"
            >
              <img src={X} alt="close" />
            </div>
          </div>
        </div>
        <div
          className="overlay"
          onClick={this.props.toggleSignUpDrawer}
          role="presentation"
          style={
            signUpOpen
              ? { visibility: 'visible', opacity: '1', right: '600px' }
              : { visibility: 'hidden', opacity: '0', right: 0 }
          }
        />
      </div>
    );
  }
}

SignUp.propTypes = {
  signUpOpen: PropTypes.bool.isRequired,
  toggleSignUpDrawer: PropTypes.func.isRequired,
  toggleSignInDrawer: PropTypes.func.isRequired,
};

export default SignUp;
