// Logos
import GuelphLogo from '../../images/gallivan/_uOfGuelphMain.png';
import KwantlenLogo from '../../images/gallivan/_kwantlenLogo.png';
import NaitsaLogo from '../../images/gallivan/_naitsaLogo.png';
import OldsLogo from '../../images/gallivan/_oldsLogo.png';
import LethbridgeLogo from '../../images/gallivan/_lethbridgeLogo.png';
import CamosunLogo from '../../images/gallivan/_camosunLogo.png';
import RidgetownLogo from '../../images/gallivan/_ridgetownLogo.png';
import RyersonLogo from '../../images/gallivan/_ryersonLogo.png';
import GeorgianLogo from '../../images/gallivan/_georgianLogo.png';
import OntarioTechLogo from '../../images/gallivan/_ontarioTechLogo.png';
import StfxLogo from '../../images/gallivan/_stfxLogo.png';
import BowValleyLogo from '../../images/gallivan/_bowValleyLogo.png';
import ConcordiaLogo from '../../images/gallivan/_concordiaLogo.png';
import GreatPlainsLogo from '../../images/gallivan/_greatPlainsLogo.png';
import CumberlandLogo from '../../images/gallivan/_cumberlandLogo.png';
import ParkLandLogo from '../../images/gallivan/_parklandLogo.png';
import NorquestLogo from '../../images/gallivan/norquestLogo.png';
import BCITLogo from '../../images/gallivan/_bcitLogo.png';
import SPSALogo from '../../images/gallivan/_spsaLogo.png';
import VSAOCLogo from '../../images/gallivan/_vsaocLogo.png';
import MacEwanLogo from '../../images/gallivan/_macewanLogo.png';
import UrsuLogo from '../../images/gallivan/ursuLogo.png';
import RedDeerLogo from '../../images/gallivan/_reddeerLogo.png';
import cbuLogo from '../../images/gallivan/_cbuLogo.png';
import purplecareLogo from '../../images/gallivan/purplecareLogo.png';
import boothLogo from '../../images/gallivan/boothLogo.png';

const gallivanSchools = [
  {
    key: 'uofguelphmain',
    title: 'University of Guelph CSA & GSA (Main Campus)',
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>80% discount on 10 one-on-one counselling sessions($500).</b>  On average, students use five sessions.',
    },
    myWellnessUrl: 'https://mystudentplan.ca/uofguelph/en/mywellness',
    insuranceInformationUrl: 'https://mystudentplan.ca/uofguelph/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://feelingbetternow.com/Application/Sign-In.aspx?OID=246&lang=en-CA',
    inkblotUrl: 'https://www.inkblottherapy.com/uofguelphmain',
    inkblotSignUpUrl: 'https://app.inkblottherapy.com/signup?affiliation=UoGcg',
    logo: GuelphLogo,
    value: 'University of Guelph: CSA & GSA',
  },
  {
    key: 'kwantlen',
    title: 'Kwantlen Student Association',
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>80% discount on 10 one-on-one counselling sessions ($500).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'https://mystudentplan.ca/kwantlen/en/mywellness',
    insuranceInformationUrl: 'https://mystudentplan.ca/kwantlen/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://www.feelingbetternow.com/Application/Sign-In.aspx?OID=296&lang=en-CA',
    inkblotUrl: 'https://www.inkblottherapy.com/kwantlen',
    inkblotSignUpUrl: 'https://app.inkblottherapy.com/signup?affiliation=KWP',
    logo: KwantlenLogo,
    value: 'Kwantlen Polytechnic University',
  },
  {
    key: 'naitsa',
    title: "Northern Alberta Institute of Technology Students' Association",
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>80% discount on 10 one-on-one counselling sessions ($500).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'https://mystudentplan.ca/nait/en/mywellness',
    insuranceInformationUrl: 'https://mystudentplan.ca/nait/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://www.feelingbetternow.com/Application/Sign-In.aspx?OID=252&lang=en-CA',
    inkblotUrl: 'https://www.inkblottherapy.com/naitsa',
    inkblotSignUpUrl: 'https://app.inkblottherapy.com/signup?affiliation=NAIT',
    logo: NaitsaLogo,
    value: 'Northern Alberta Institute of Technology',
  },
  {
    key: 'olds',
    title: "Students' Association of Olds College",
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>80% discount on 7 one-on-one counselling sessions ($300).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'https://mystudentplan.ca/olds/en/mywellness',
    insuranceInformationUrl: 'https://mystudentplan.ca/olds/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://www.feelingbetternow.com/Application/Sign-In.aspx?OID=297&lang=en-CA ',
    inkblotUrl: 'https://www.inkblottherapy.com/olds',
    inkblotSignUpUrl: 'https://app.inkblottherapy.com/signup?affiliation=olds',
    logo: OldsLogo,
    value: 'Olds College',
  },
  {
    key: 'lethbridge',
    title: "Lethbridge College Students' Association",
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>80% discount on 10 one-on-one counselling sessions ($500).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'https://mystudentplan.ca/lethbridge/en/mywellness',
    insuranceInformationUrl:
      'https://mystudentplan.ca/lethbridge/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://www.feelingbetternow.com/Application/Sign-In.aspx?OID=16&lang=en-CA',
    inkblotUrl: 'https://www.inkblottherapy.com/lethbridge',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=lethbridge',
    logo: LethbridgeLogo,
    value: 'Lethbridge College',
  },
  {
    key: 'camosun',
    title: 'Camosun College Student Society',
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>65% discount on 7 one-on-one counselling sessions ($300).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'https://mystudentplan.ca/camosun/en/mywellness',
    insuranceInformationUrl: 'https://mystudentplan.ca/camosun/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://feelingbetternow.com/Application/Sign-In.aspx?OID=298&lang=en-CA',
    inkblotUrl: 'https://www.inkblottherapy.com/camosun',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=camosun',
    logo: CamosunLogo,
    value: 'Camosun College',
  },
  {
    key: 'ridgetown',
    title: 'University of Guelph - Ridgetown Campus',
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>40% discount on 7 one-on-one counselling sessions ($300).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'https://mystudentplan.ca/ridgetown/en/mywellness',
    insuranceInformationUrl: 'https://mystudentplan.ca/ridgetown/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://www.feelingbetternow.com/Application/Sign-In.aspx?OID=22&lang=en-CA',
    inkblotUrl: 'https://www.inkblottherapy.com/ridgetown',
    inkblotSignUpUrl: 'https://app.inkblottherapy.com/signup?affiliation=UoGr',
    logo: RidgetownLogo,
    value: 'University of Guelph: Ridgetown',
  },
  {
    key: 'ryerson',
    title: "Ryerson Students' Union",
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>10 one-on-one counselling sessions ($500).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'https://mystudentplan.ca/rsu/en/mywellness',
    insuranceInformationUrl: 'https://www.mystudentplan.ca/rsu/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://www.feelingbetternow.com/Application/Sign-In.aspx?OID=247&lang=en-CA',
    inkblotUrl: 'https://www.inkblottherapy.com/ryerson',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=ryerson',
    logo: RyersonLogo,
    value: 'Ryerson University',
  },
  {
    key: 'georgian',
    title: 'Georgian College',
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>80% discount on 7 one-on-one counselling sessions ($300).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'https://mystudentplan.ca/georgian/en/mywellness',
    insuranceInformationUrl: 'https://mystudentplan.ca/georgian/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://www.feelingbetternow.com/Application/Sign-In.aspx?OID=11&lang=en-CA',
    inkblotUrl: 'https://www.inkblottherapy.com/georgian',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=georgian',
    logo: GeorgianLogo,
    value: 'Georgian College',
  },
  {
    key: 'ontario_tech',
    title: 'Ontario Tech',
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>80% discount on 7 one-on-one counselling sessions ($300).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'http://www.mystudentplan.ca/ontario-tech/en/mywellness',
    insuranceInformationUrl:
      'https://www.mystudentplan.ca/ontario-tech/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://feelingbetternow.com/Application/Sign-In.aspx?OID=300&lang=en-CA',
    inkblotUrl: 'https://www.inkblottherapy.com/ontario-tech',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=ontario_tech',
    logo: OntarioTechLogo,
    value: 'Ontario Tech',
  },
  {
    key: 'stfx',
    title: "St FX Student's Union",
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>20 one-on-one counselling sessions ($900).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'http://www.mystudentplan.ca/stfxsu/en/mywellness',
    insuranceInformationUrl:
      'https://www.mystudentplan.ca/stfxsu/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://feelingbetternow.com/Application/Sign-In.aspx?OID=301&lang=en-CA',
    inkblotUrl: 'https://www.inkblottherapy.com/stfx',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=st_fx_student_union',
    logo: StfxLogo,
    value: "St FX Student's Union",
  },
  {
    key: 'bowvalley',
    title: 'Bow Valley College',
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>40% discount on 7 one-on-one counselling sessions ($300).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'http://www.mystudentplan.ca/bowvalley/en/mywellness',
    insuranceInformationUrl:
      'https://www.mystudentplan.ca/bowvalley/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://feelingbetternow.com/Application/Sign-In.aspx?OID=20&lang=en-CA',
    inkblotUrl: 'https://www.inkblottherapy.com/bowvalley',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=bow_valley_college',
    logo: BowValleyLogo,
    value: 'Bow Valley College',
  },
  {
    key: 'concordia',
    title: 'Concordia University of Edmonton',
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>80% discount on 7 one-on-one counselling sessions ($300).</b>On average, students use five sessions.',
    },
    myWellnessUrl: 'http://www.mystudentplan.ca/csa/en/mywellness',
    insuranceInformationUrl: 'https://www.mystudentplan.ca/csa/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://feelingbetternow.com/Application/Sign-In.aspx?OID=9&lang=en-CA',
    inkblotUrl: 'https://www.inkblottherapy.com/concordia',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=concordia_university_of_edmonton',
    logo: ConcordiaLogo,
    value: 'Concordia University of Edmonton',
  },
  {
    key: 'greatplains',
    title: 'Great Plains College',
    benefitsText: {
      __html:
        'Great Plains College is supporting your mental health and will cover the cost of all of your sessions on Inkblot!',
    },
    myWellnessUrl: 'https://www.mystudentplan.ca/gpc/en/mywellness',
    insuranceInformationUrl: 'https://www.mystudentplan.ca/gpc/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://feelingbetternow.com/Application/Sign-In.aspx?OID=302&lang=en-CA',
    inkblotUrl: 'https://www.inkblottherapy.com/greatplains',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=great_plains_college',
    logo: GreatPlainsLogo,
    value: 'Great Plains College',
  },
  {
    key: 'cumberland',
    title: 'Cumberland College',
    benefitsText: {
      __html:
        'As part of your student benefits, you get 45% discount on 9 one-on-one counselling sessions ($400). On average, students use five sessions.',
    },
    myWellnessUrl: 'https://www.mystudentplan.ca/cumberland/en/mywellness',
    insuranceInformationUrl:
      'https://www.mystudentplan.ca/cumberland/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://feelingbetternow.com/Application/Sign-In.aspx?OID=303&lang=en-CA',
    inkblotUrl: 'https://www.inkblottherapy.com/cumberland',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=cumberland_college',
    logo: CumberlandLogo,
    value: 'Cumberland College',
  },
  {
    key: 'parkland',
    title: 'Parkland College',
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>45% discount on 9 one-on-one counselling sessions ($400).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'https://www.mystudentplan.ca/parkland/en/mywellness',
    insuranceInformationUrl:
      'https://www.mystudentplan.ca/parkland/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://feelingbetternow.com/Application/Sign-In.aspx?OID=304&lang=en-CA',
    inkblotUrl: 'https://www.inkblottherapy.com/parkland',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=parkland_college',
    logo: ParkLandLogo,
    value: 'Parkland College',
  },
  {
    key: 'norquest',
    title: 'Norquest College',
    benefitsText: {
      __html:
        'As part of your student benefits, you get 7 <b>one-on-one counselling sessions ($300).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'http://www.mystudentplan.ca/norquest/en/mywellness',
    insuranceInformationUrl:
      'https://www.mystudentplan.ca/norquest/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://feelingbetternow.com/Application/Sign-In.aspx?OID=17&lang=en-CA',
    inkblotUrl: 'https://www.inkblottherapy.com/norquest',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=norquest',
    logo: NorquestLogo,
    value: 'Norquest College',
  },
  {
    key: 'bcit',
    title: 'BCIT',
    benefitsText: {
      __html:
        'As part of your student benefits, you get 80% discount on 9 <b>one-on-one counselling sessions ($400).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'http://www.mystudentplan.ca/bcit/en/mywellness',
    insuranceInformationUrl: 'https://www.mystudentplan.ca/bcit/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://feelingbetternow.com/Application/Sign-In.aspx?OID=8&lang=en-CA',
    inkblotUrl: 'https://www.inkblottherapy.com/bcit',
    inkblotSignUpUrl: 'https://app.inkblottherapy.com/signup?affiliation=bcit',
    logo: BCITLogo,
    value: 'BCIT',
  },
  {
    key: 'saskpoly',
    title: 'Saskatchewan Polytechnic',
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>7 one-on-one counselling sessions ($300).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'https://www.mystudentplan.ca/saskpolytech/en/mywellness',
    insuranceInformationUrl:
      'https://www.mystudentplan.ca/saskpolytech/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://www.feelingbetternow.com/Application/Sign-In.aspx?OID=21&lang=en-CA',
    inkblotUrl: 'https://www.inkblottherapy.com/saskpoly',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=saskpoly',
    logo: SPSALogo,
    value: 'Saskatchewan Polytechnic',
  },
  {
    key: 'okanagan',
    title: 'Okanagan College',
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>40% discount on 7 one-on-one counselling sessions ($300). </b>On average, students use five sessions to start to feel their best.',
    },

    myWellnessUrl: 'https://www.mystudentplan.ca/okanagan/en/mywellness',
    insuranceInformationUrl:
      'https://www.mystudentplan.ca/okanagan/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://www.feelingbetternow.com/Application/Sign-In.aspx?OID=250&lang=en-CA',
    inkblotUrl: 'https://www.inkblottherapy.com/okanagan',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=okanagan',
    logo: VSAOCLogo,
    value: 'Okanagan College',
  },
  {
    key: 'macewan',
    title: "Students' Association of MacEwan University",
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>80% discount on 10 one-on-one counselling sessions ($500).</b>On average, students use five sessions.',
    },
    myWellnessUrl: 'https://www.mystudentplan.ca/macewan/en/mywellness',
    insuranceInformationUrl: 'https://www.mystudentplan.ca/macewan/en/home',
    mentalHealthAssessmentUrl:
      'https://feelingbetternow.com/Application/Sign-In.aspx?OID=311&lang=en-CA',
    inkblotUrl: 'https://www.inkblottherapy.com/macewan',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=macewan',
    logo: MacEwanLogo,
    value: 'MacEwan University',
  },
  {
    key: 'ursu',
    title: 'URSU',
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>80% discount on 10 one-on-one counselling sessions ($500).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'https://www.mystudentplan.ca/ursu/en/mywellness',
    insuranceInformationUrl: 'https://ursu.ca/services/health-dental-plan/',
    mentalHealthAssessmentUrl:
      'https://feelingbetternow.com/Application/Sign-In.aspx?OID=309&lang=en-CA',
    inkblotUrl: 'https://www.inkblottherapy.com/ursu',
    inkblotSignUpUrl: 'https://app.inkblottherapy.com/signup?affiliation=ursu',
    logo: UrsuLogo,
    value: 'URSU',
  },
  {
    key: 'reddeer',
    title: 'Red Deer College',
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>80% discount on 10 one-on-one counselling sessions ($500).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'https://www.mystudentplan.ca/reddeer/en/mywellness',
    insuranceInformationUrl:
      'https://www.mystudentplan.ca/reddeer/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://feelingbetternow.com/Application/Sign-In.aspx?OID=310&lang=en-CA',
    inkblotUrl: 'https://www.inkblottherapy.com/reddeer',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=reddeer',
    logo: RedDeerLogo,
    value: 'Red Deer College',
  },
  {
    key: 'cbu',
    title: 'Cape Breton University',
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>80% discount on 10 one-on-one counselling sessions ($500).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'https://mystudentplan.ca/cbusu/en/mywellness',
    insuranceInformationUrl: 'https://studentbenefits.ca/my-coverage',
    mentalHealthAssessmentUrl:
      'https://www.feelingbetternow.com/Application/Sign-In.aspx?OID=290&lang=en-CA',
    inkblotUrl: 'www.inkblottherapy.com/cbu',
    inkblotSignUpUrl: 'https://app.inkblottherapy.com/signup?affiliation=cbu',
    logo: cbuLogo,
    value: 'Cape Breton University',
  },
  {
    key: 'western-purplecare',
    title: 'Western PurpleCare',
    benefitsText: {
      __html:
        'As part of your student benefits, you get sixteen one-on-one counselling sessions ($750). On average, students use five sessions.',
    },
    myWellnessUrl: 'https://www.mystudentplan.ca/purplecare/en/mywellness',
    insuranceInformationUrl: 'https://studentbenefits.ca/my-coverage',
    mentalHealthAssessmentUrl:
      'https://www.feelingbetternow.com/western-purplecare',
    inkblotUrl: 'www.inkblottherapy.com/western-purplecare',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=western-purplecare',
    logo: purplecareLogo,
    value: 'Western PurpleCare',
  },
  {
    key: 'booth',
    title: 'Booth University College',
    benefitsText: {
      __html:
        'As part of your student benefits, you get 40% discount on 7 one-on-one counselling sessions ($300). On average, students use five sessions.',
    },
    myWellnessUrl: 'https://mystudentplan.ca/booth/en/mywellness',
    insuranceInformationUrl: 'https://www.mystudentplan.ca/booth/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://www.feelingbetternow.com/Application/Sign-In.aspx?OID=321&lang=en-CA',
    inkblotUrl: 'www.inkblottherapy.com/booth',
    inkblotSignUpUrl: 'https://app.inkblottherapy.com/signup?affiliation=booth',
    logo: boothLogo,
    value: 'Booth University College',
  },
];

export default gallivanSchools;
