import fugroLogo from '../../images/corporate-nav/fugro-logo.png';
import desjardinLogo from '../../images/corporate-nav/desjardin-logo.png';
import wpoLogo from '../../images/corporate-nav/wpo-logo.jpg';
import inkblotLogo from '../../images/custom/logos/inkblot-logo-mlf.png';
import beaconLogo from '../../images/corporate-nav/beacon-logo.jpg';
import lifespeakLogo from '../../images/corporate-nav/lifespeak-logo.png';
import morneauLogo from '../../images/custom/logos/morneau-logo.png';
import selfhelpworksLogo from '../../images/corporate-nav/selfhelpworks-logo.png';
import sunlifeLogo from '../../images/corporate-nav/sunlife-logo.png';
import yrLogo from '../../images/corporate-nav/yr-logo.jpg';

const corps = [
  {
    marketingURL: 'fugro_nav',
    path: 'fugro_nav',
    logo: fugroLogo,
    title: 'Fugro Benefits',
    subTitle: 'Learn more about your comprehensive benefits package.',
    providers: [
      {
        logo: desjardinLogo,
        maxWidth: '328px',
        width: '21%',
        path: 'desjardin',
      },
      {
        logo: wpoLogo,
        maxWidth: '315px',
        width: '21%',
        path: 'wpo',
      },
      {
        logo: inkblotLogo,
        maxWidth: '315px',
        width: '21%',
        path: 'inkblot',
      },
    ],
    provider_one: {
      logo: desjardinLogo,
      path: 'desjardin',
      type: 'Health & Medical',
      name: 'Desjardins',
      primaryDescription: 'Desjardins provides Fugro Canada employees with Health, Dental and Paramedical benefits through its group insurance offerings. This benefit also includes a generous combined maximum of $1000 per year for services with a Psychologist, Psychotherapist and Social Worker.',
      buttonLabel: 'Launch Desjardins',
      buttonUrl: 'https://www.desjardinslifeinsurance.com/en/group-insurance-plan-members#',
    },
    provider_two: {
      logo: wpoLogo,
      path: 'wpo',
      type: 'Employee Assistance Program',
      name: 'WPO (Workplace Options)',
      primaryDescription: 'Fugro’s Global Employee Assistance Program (EAP) through Workplace Options is a confidential counselling and referral program available to eligible employees and their families.<br><br>Accessible online by visiting <a href="http://global.resourcesforyourlife.com/">global.resourcesforyourlife.com</a> with the company code "Fugro" and via their 24-hour line at 1-877-847-4525. The EAP provides a variety of resources.<br><br><b>Most suitable for those seeking short-term counselling or referral.</b>',
      buttonLabel: 'Launch WPO',
      buttonUrl: 'http://global.resourcesforyourlife.com',
    },
    provider_three: {
      logo: inkblotLogo,
      path: 'inkblot',
      type: 'Virtual Therapy',
      name: 'Inkblot Therapy',
      primaryDescription: "Inkblot Therapy is Canada's top provider of online video-based one-on-one or couples counselling. Care is provided by highly experienced professionals across Canada. Inkblot highly values long-term relationships with care providers. Sessions may be reimbursable through your paramedical health coverage.<br><br><b>Most suitable for those seeking online counselling and a longer-term relationship with their counsellor.</b>",
      buttonLabel: 'Launch Inkblot',
      buttonUrl: 'https://inkblottherapy.com/fugrocanada',
    },
  },
  {
    marketingURL: 'yr_nav',
    path: 'yr_nav',
    logo: yrLogo,
    title: 'Keeping You Well, YR',
    subTitle: 'Learn more about your mental health benefits.',
    providers: [
      {
        logo: inkblotLogo,
        maxWidth: '168px',
        width: '12%',
        path: 'inkblot',
      },
      {
        logo: morneauLogo,
        maxWidth: '155px',
        width: '12%',
        path: 'morneau',
      },
      {
        logo: lifespeakLogo,
        maxWidth: '155px',
        width: '12%',
        path: 'lifespeak',
      },
      {
        logo: sunlifeLogo,
        maxWidth: '168px',
        width: '12%',
        path: 'sunlife',
      },
      {
        logo: selfhelpworksLogo,
        maxWidth: '155px',
        width: '12%',
        path: 'selfhelpworks',
      },
      {
        logo: beaconLogo,
        maxWidth: '155px',
        width: '12%',
        path: 'beacon',
      },
    ],
    provider_one: {
      logo: inkblotLogo,
      path: 'inkblot',
      type: 'Virtual Counselling',
      name: 'Inkblot Therapy',
      primaryDescription: 'Inkblot Therapy is Canada’s top provider of online video-based one-on-one or couples counselling. Care is provided by highly experienced professionals across Canada through secure video counseling offered at half the cost and without the barriers of traditional therapy. Your first 30-minute individual counselling session is complimentary. Subsequent sessions are reimbursable through your Extended Benefit Plan or Health Spending Account (HSA).',
      secondaryDescription: 'Most suitable for those seeking online counselling and a longer-term relationship with their counsellor. Users can seek a counsellor with expertise in a specific area, language or religion to help curate a match. Book your appointment online as soon as the same or next day, with access to crisis support 24/7.',
      buttonLabel: 'Launch Inkblot',
      buttonUrl: 'https://inkblottherapy.com/yorkregion',
    },
    provider_two: {
      logo: morneauLogo,
      path: 'morneau',
      type: 'Employee and Family Assistance Program (EFAP)',
      name: 'Morneau Shepell',
      primaryDescription: 'Our Employee and Family Assistance Program (EFAP) offers free, confidential and voluntary support 24/7 for any work, health or life concern. You and your immediate family members (as defined in your employee benefit plan) can receive support from caring professionals across a variety of platforms including video counseling, group counseling, telephonic or webchat. With real-time online chat (First Chat), you can book a service or speak to a counsellor immediately, no appointment needed. Worklife services include financial, family, and legal support, as well as nutrition, health coaching and naturopathy.',
      secondaryDescription: 'Download the free MyEAP app to instantly chat with Masters-level clinicians, or call toll-free 24 hours per day, 7 days a week at 1-844-880-9142.',
      buttonLabel: 'Launch EFAP',
      buttonUrl: 'https://www.workhealthlife.com/Home/WithParam?SYMORG=11148&lang=en-CA',
    },
    provider_three: {
      logo: lifespeakLogo,
      path: 'lifespeak',
      type: 'Total Well-Being Platform of Expert Advice',
      name: 'LifeSpeak',
      primaryDescription: 'LifeSpeak is a total well-being online platform to help staff remain present, productive and thrive in life. The platform makes it easy for you and your families to access the support you need to overcome hurdles and accomplish goals, both personal and professional. With over 1000 videos and podcasts on topics ranging from physical health and mental illness to personal and leader development, LifeSpeak features acclaimed authors, medical professionals, and professors offering 24/7 expert advice at your fingertips.',
      secondaryDescription: 'LifeSpeak is available to you at anytime from anywhere. To access the resource, download the free app or visit york.lifespeak.com and create your own account, or sign in with our Corporate ID wellness (case sensitive)',
      buttonLabel: 'Launch LifeSpeak',
      buttonUrl: 'https://york.lifespeak.com/Account/Login?ReturnUrl=%2fwelcome',
    },
    provider_four: {
      logo: sunlifeLogo,
      path: 'sunlife',
      type: 'Health Benefits',
      name: 'Sun Life',
      primaryDescription: 'Mental health paramedical benefits coverage of $1000 per benefit year is available for eligible plan members through the extended health benefit or Health Spending Account (HSA). Be sure to check your benefit booklet for coverage details. NOTE: CUPE members and Non Union employees have coverage for a psychologist and a social worker with a Masters of Social Work (MSW), but do NOT have coverage for a psychotherapist. ONA members have coverage for a psychologist or a social worker (who has their MSW) or a psychotherapist. Additional mental health resources can be found at <a href="https://luminohealth.sunlife.ca">luminohealth.sunlife.ca</a>',
      secondaryDescription: 'Find the benefit booklet that applies to you, or register an account on the Sun Life website to view your coverage and make claims online.',
      buttonLabel: 'Launch Sun Life',
      buttonUrl: 'https://luminohealth.sunlife.ca',
    },
    provider_five: {
      logo: selfhelpworksLogo,
      path: 'selfhelpworks',
      type: 'Self-Development Online Training ',
      name: 'SelfHelpWorks ',
      primaryDescription: 'Based on cognitive behaviour therapy training, seven self-directed courses are available to help you reach your personal health goals. From managing stress or addictions, to eating better or moving more, these online behaviour modification courses run anywhere from 6 to 12 weeks and are designed to help you build healthy habits for life.',
      secondaryDescription: 'To find the course for you, visit <a href="https://portals.selfhelpworks.com/public/app2/#!/landing">portals.selfhelpworks.com</a> and enter activation code YR-SHW',
      buttonLabel: 'Launch SelfHelpWorks',
      buttonUrl: 'https://portals.selfhelpworks.com/public/app2/#!/landing',
    },
    provider_six: {
      logo: beaconLogo,
      path: 'beacon',
      type: 'Guided Digital Therapy',
      name: 'Beacon',
      primaryDescription: 'Available through our Extended Health Benefits, Beacon offers digital therapy guided by an accredited therapist. The 12-week course begins with a personal assessment followed by cognitive behavioural therapy activities customized by your dedicated therapist to help you manage your thoughts and feelings. Most suitable to improve mild to moderate symptoms of depression, anxiety, post-traumatic stress injury (PTSI/PTSD) and insomnia.',
      secondaryDescription: "Complete Beacon therapy at your own pace, anytime and anywhere you're comfortable. Most people complete their personalized course in 6 to 10 weeks.<br><br>Ready to get started free of charge? Sign up for a secure account (valid email address required).",
      buttonLabel: 'Launch Beacon',
      buttonUrl: 'https://info.mindbeacon.com/btn542',
    },
    provider_seven: {
      logo: yrLogo,
      path: 'other',
      type: 'Free Community Supports',
      name: 'Additional Supports during COVID19',
      primaryDescription: 'In response to COVID19, the Government of Canada has secured additional resources to support you:<ul><li>ConnexOntario – call 1-866-531-2600 for addiction, mental health and problem gambling support</li><li>WellCan – download the app for a collection of mental health resources available to everyone in Canada</li><li>Wellness Together Canada - a government resource offering free online tools, apps and connections to trained volunteers and qualified mental health professionals to help Canadians get back on track</li></ul>',
      secondaryDescription: 'Remember, you are not alone. If you or someone you know is in crisis, call 911 immediately or go to your nearest emergency department for assistance.',
      buttonLabel: 'Launch Your Local Crisis Centre',
      buttonUrl: 'https://thelifelinecanada.ca/suicide-prevention-crisis-centre-contact-information/crisis-centres/canadian-crisis-centres/',
    },
  },
];

export default corps;
